import * as React from 'react';
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import RawMaterialService from "../../services/RawMaterials.service";
import LanguagesService from "../../services/Languages.service";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { CustomCheckbox, CustomDropDown, CustomInput, CustomTextArea } from "../../components/CustomComponents"
import { useTranslation } from "react-i18next";
import { avaiableLangList } from '../../i18nextConf'


export default function RawMaterialEdit(props) {

    const { t } = useTranslation();
    const isAdmin = props.isAdmin;

    const [languages, setLanguages] = React.useState([]);
    const [newResource, setNewResource] = React.useState(false);
    const [selectedLenguage, setSelectedLenguage] = React.useState({ name: 'Loading...', languageId: "es-ES" });

    React.useEffect(() => {
        LanguagesService.getLanguages()
            .then(response => response.data.contract)
            .then(json => {
                setLanguages(json);

                var selected = localStorage.getItem("i18nextLng") || "en";
                var selectedLangDetails = avaiableLangList.find(lang => lang.short === selected);
                var defaultLang = json.find(lang => lang.languageId === selectedLangDetails.id);

                setSelectedLenguage(defaultLang);
            });



    }, [props.rawMaterialId]);

    const [rawMaterialLang, setRawMaterialLang] = React.useState({
        "rawMaterialId": props.rawMaterialId,
        "language": selectedLenguage,
        "name": "",
        "description": "",
        "remarks": "",
    });

    const [editableRawMaterial, setEditableRawMaterial] = React.useState({
        "rawMaterialId": "",
        "rawMaterialGroup": { "rawMaterialGroupId": 0, "name": "" },
        "rawMaterialBaseId": 0,
        "rawMaterialNumberId": 0,
        "language": selectedLenguage,
        "name": "",
        "description": "",
        "remarks": "",
        "isDisabled": false
    });

    const [groups, setGroups] = React.useState([]);
    const [loading, setLoading] = React.useState(true);


    React.useEffect(() => {
        RawMaterialService.getGroups()
            .then(response => response.data.contract)
            .then(json => {
                setGroups(json);
            });
    }, [props.rawMaterialId]);

    React.useEffect(() => {
        RawMaterialService.getRawMaterialTranslation(props.materialData.rawMaterialId, selectedLenguage.languageId)
            .then(response => response.data)
            .then(json => {
                setRawMaterialLang({
                    "rawMaterialId": props.materialData.rawMaterialId,
                    "language": selectedLenguage,
                    "name": json.contract.name,
                    "description": json.contract.description,
                    "remarks": json.contract.remarks,
                    "exists": true
                });
                setNewResource(false);
                setLoading(false);
            })
            .catch(error => {
                setRawMaterialLang({
                    "rawMaterialId": props.materialData.rawMaterialId,
                    "language": selectedLenguage,
                    "name": "",
                    "description": "",
                    "remarks": "",
                    "exists": false
                });
                setNewResource(true);
                setLoading(false);
            });
    }, [props, selectedLenguage]);

    React.useEffect(() => {
        setEditableRawMaterial({
            "rawMaterialId": props.materialData.rawMaterialId,
            "rawMaterialGroup": (groups.find(group => group.rawMaterialGroupId === props.materialData.rawMaterialGroupId)),
            "rawMaterialBaseId": props.materialData.rawMaterialBaseId,
            "rawMaterialNumberId": props.materialData.rawMaterialNumberId,
            "language": rawMaterialLang.language,
            "name": rawMaterialLang.name,
            "description": rawMaterialLang.description,
            "remarks": rawMaterialLang.remarks,
            "isDisabled": false
        });

    }, [rawMaterialLang, groups, props.materialData.rawMaterialBaseId, props.materialData.rawMaterialGroupId, props.materialData.rawMaterialId, props.materialData.rawMaterialNumberId]);


    const requiredValidator = (value) => {
        return value ? "" : "This field is required";
    }

    const handleSubmit = (dataItem) => {

        if (newResource) {

            RawMaterialService.saveRawMaterialLocalization({
                "rawMaterialId": dataItem.rawMaterialId,
                "languageId": selectedLenguage.languageId,
                "name": dataItem.name,
                "description": dataItem.description,
                "remarks": dataItem.remarks
            });

        }
        else {
            RawMaterialService.updateRawMaterialLocalization({
                "rawMaterialId": dataItem.rawMaterialId,
                "languageId": selectedLenguage.languageId,
                "name": dataItem.name,
                "description": dataItem.description,
                "remarks": dataItem.remarks
            });

        }

        setEditableRawMaterial(dataItem);
    }

    return (
        <div>
            <Form
                onSubmit={handleSubmit}
                initialValues={editableRawMaterial}
                key={JSON.stringify(editableRawMaterial)}
                render={(formRenderProps) => (
                    <FormElement>
                        <fieldset>
                            <div className="card">
                                <div className="card-header d-flex justify-content-between align-items-center">

                                    <b>{t("RawMaterials_Details_GeneralData_Title")}</b>

                                    <DropDownList
                                        label={t("Global_Label_Text_Language")}
                                        name="language"
                                        loading={loading}
                                        textField="name"
                                        value={selectedLenguage}
                                        dataItemKey="languageId"
                                        style={{ width: '200px' }}
                                        onChange={e => setSelectedLenguage(e.value)}
                                        data={languages}
                                    />

                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-sm-3">
                                            <Field
                                                label={t("RawMaterials_ID_Label")}
                                                loading={loading}
                                                disabled={true}
                                                name={'rawMaterialId'}
                                                component={CustomInput}
                                                onChange={e => setEditableRawMaterial({ ...editableRawMaterial, name: e.target.value })}
                                            />
                                        </div>
                                        <div className="col-sm-6">
                                            <Field
                                                label={t("RawMaterials_Name_Label")}
                                                name={'name'}
                                                loading={loading}
                                                disabled={!isAdmin}
                                                component={CustomInput}
                                                validator={[requiredValidator]}
                                                onChange={e => setEditableRawMaterial({ ...editableRawMaterial, name: e.target.value })}
                                            />
                                        </div>
                                        <div className="col-sm-3">
                                            <Field
                                                label={t("RawMaterials_IsDisabled_Label")}
                                                loading={loading}
                                                disabled={!isAdmin}
                                                name={'IsDisabled'}
                                                onChange={e => setEditableRawMaterial({ ...editableRawMaterial, name: e.target.value })}
                                                component={CustomCheckbox} />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <Field
                                                label={t("RawMaterials_Group_Label")}
                                                loading={loading}
                                                disabled={true}
                                                name="rawMaterialGroup"
                                                textField="name"
                                                value={editableRawMaterial.rawMaterialGroup}
                                                component={CustomDropDown}
                                                dataItemKey="rawMaterialGroupId"
                                                style={{ width: "100%" }}
                                                onChange={e => { setEditableRawMaterial({ ...editableRawMaterial, name: e.target.value }) }}
                                                options={groups}
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <Field
                                                label={t("RawMaterials_Description_Label")}
                                                name={'description'}
                                                loading={loading}
                                                autoSize={true}
                                                disabled={!isAdmin}
                                                component={CustomTextArea}
                                                maxLength={1000}
                                                onChange={e => setEditableRawMaterial({ ...editableRawMaterial, name: e.target.value })}
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <Field
                                                label={t("RawMaterials_Remarks_Label")}
                                                name={'remarks'}
                                                loading={loading}
                                                autoSize={true}
                                                rows={2}
                                                disabled={!isAdmin}
                                                maxLength={1000}
                                                component={CustomTextArea}
                                                onChange={e => setEditableRawMaterial({ ...editableRawMaterial, name: e.target.value })}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </fieldset>
                        {isAdmin ?
                            (<div className="float-sm-right k-form-buttons">
                                <button
                                    type={'submit'}
                                    disabled={!formRenderProps.allowSubmit}
                                    className="k-button"
                                >
                                    <span className="k-icon k-i-save" /> &nbsp; {t("Global_Button_Text_Save")}
                                </button>
                            </div>) : (<span />)}
                    </FormElement>)}
            />

            <br />
            <br />
        </div>
    );
}
