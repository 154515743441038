import React from 'react';
import AdditiveEdit from '../../Models/AdditiveCategory/Edit';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import AuthService from "../../services/auth.service";

export default function AdditivesDetails(props) {

    const { params } = props.match;
    const { t } = useTranslation();

    return (
        <div>
            <br />
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to={'/home/'}>{t("Home_Breadcrumb_Title")}</Link></li>
                    <li className="breadcrumb-item"><Link to={'/AdditiveCategories'}>{t("AdditiveCategories_Breadcrumb_Title")}</Link></li>
                    <li className="breadcrumb-item active">{params.additiveCategoryId}</li>
                </ol>
            </nav>


            <AdditiveEdit
                additiveCategoryId={params.additiveCategoryId}
                isAdmin={AuthService.isUSerAdmin()}
            />

            <br />
            <br />
        </div >
    );


}
