import * as React from 'react';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { DropDownList } from "@progress/kendo-react-dropdowns";
import AdditivesService from "../../services/Additives.service";
import LanguagesService from "../../services/Languages.service";
import { useTranslation } from "react-i18next";
import { avaiableLangList } from '../../i18nextConf'
import { GridCustomAltIdCommandCell } from "../../components/GridCustomAltIdCommandCell";
import { GridCustomLoadingCell } from "../../components/GridCustomLoadingCell";
import { PanelCustomLoader } from "../../components/PanelCustomLoader";


export default function AnalyticComponentsAlternativeIdentifiers(props) {

    const { t } = useTranslation();
    const isAdmin = props.isAdmin;
    const editField = "inEdit";

    const [languages, setLanguages] = React.useState([]);
    const [selectedLenguage, setSelectedLenguage] = React.useState({ name: "Loading...", languageId: "" });
    const [additiveAlternativeIDs, setAdditiveAlternativeIDs] = React.useState([]);
    const [loading, setLoading] = React.useState(true);

    React.useEffect(() => {

        LanguagesService.getLanguages()
            .then(response => response.data.contract)
            .then(json => {
                setLanguages(json);

                var selected = localStorage.getItem("i18nextLng") || "en";
                var selectedLangDetails = avaiableLangList.find(lang => lang.short === selected);
                var defaultLang = json.find(lang => lang.languageId === selectedLangDetails.id);
                setSelectedLenguage(defaultLang);
            });

    }, []);

    React.useEffect(() => {

        setLoading(true);

        if (selectedLenguage.languageId !== "")
            AdditivesService.getAlternativeIdentifiers(props.additiveId, selectedLenguage.languageId)
                .then(response => response.data)
                .then(json => {
                    setAdditiveAlternativeIDs(json.contract);
                    setLoading(false);
                }).catch(enf => {
                    setAdditiveAlternativeIDs([]);
                    setLoading(false);
                }

                );
    }, [props.additiveId, selectedLenguage]);

    const remove = dataItem => {

        AdditivesService.deleteAlternativeIdentifier({
            "additiveAlternativeIdentifierId": dataItem.additiveAlternativeIdentifierId,
            "additiveId": dataItem.additiveId
        }).then(response => {

            AdditivesService.getAlternativeIdentifiers(props.additiveId, selectedLenguage.languageId)
                .then(response => response.data)
                .then(json => { setAdditiveAlternativeIDs(json.contract); setLoading(false); });
        });
    };

    const add = dataItem => {

        dataItem.inEdit = true;

        AdditivesService.saveAlternativeIdentifiers({
            "additiveAlternativeIdentifierId": Math.floor(Math.random() * 1000) + 1,
            "additiveId": props.additiveId,
            "languageId": selectedLenguage.languageId,
            "alternativeName": dataItem.alternativeName
        }).then(
            response => {

                var data = additiveAlternativeIDs.map(item => (item.additiveAlternativeIdentifierId === dataItem.additiveAlternativeIdentifierId) ?
                    { ...item, additiveAlternativeIdentifierId: response.data.contract.additiveAlternativeIdentifierId, inEdit: false, isNew: false } : item);

                setAdditiveAlternativeIDs(data);
            }
        );
    };

    const update = dataItem => {

        dataItem.inEdit = false;

        AdditivesService.updateAlternativeIdentifier({
            "additiveAlternativeIdentifierId": dataItem.additiveAlternativeIdentifierId,
            "additiveId": props.additiveId,
            "languageId": dataItem.languageId,
            "alternativeName": dataItem.alternativeName
        })
            .then(response => {
                AdditivesService.getAlternativeIdentifiers(props.additiveId, selectedLenguage.languageId)
                    .then(response => response.data)
                    .then(json => { setAdditiveAlternativeIDs(json.contract); setLoading(false); });
            })

    };

    const discard = dataItem => {

        setAdditiveAlternativeIDs(additiveAlternativeIDs.filter(item => item.additiveAlternativeIdentifierId !== dataItem.additiveAlternativeIdentifierId));

    };

    const cancel = dataItem => {

        const itemEdit = additiveAlternativeIDs.find(item => (item.additiveAlternativeIdentifierId === dataItem.additiveAlternativeIdentifierId));

        setAdditiveAlternativeIDs(additiveAlternativeIDs.map(itemMap =>
            itemEdit.additiveAlternativeIdentifierId === itemMap.additiveAlternativeIdentifierId ?
                { ...itemMap, inEdit: false } : itemMap));

    };

    const enterEdit = dataItem => {

        setAdditiveAlternativeIDs(additiveAlternativeIDs.map(item => (item.additiveAlternativeIdentifierId === dataItem.additiveAlternativeIdentifierId) ? { ...item, inEdit: true, isNew: false } : item));
    };

    const itemChange = event => {

        const data = additiveAlternativeIDs.map(item => item.additiveAlternativeIdentifierId === event.dataItem.additiveAlternativeIdentifierId ? { ...item, [event.field]: event.value } : item);

        setAdditiveAlternativeIDs(data);
    };

    const CommandCell = props => (
        <GridCustomAltIdCommandCell
            {...props}
            edit={enterEdit}
            remove={remove}
            add={add}
            discard={discard}
            update={update}
            cancel={cancel}
            editField={editField}
        />
    );

    const addNew = () => {

        const newDataItem = { isNew: true, inEdit: true, alternativeName: "", additiveAlternativeIdentifierId: Math.floor(Math.random() * 1000) + 1 };

        const data = [newDataItem, ...additiveAlternativeIDs];

        setAdditiveAlternativeIDs(data);
    };

    return (
        <div>
            {loading && PanelCustomLoader}

            <div className="card">
                <div className="card-header d-flex justify-content-between align-items-center">
                    {isAdmin ? (<button
                        title={t("Additive_AlternativeIdentifiers_Button_Tooltip_New")}
                        className="k-button k-primary"
                        onClick={addNew} >
                        &nbsp;<span className="k-icon k-i-plus-circle" />&nbsp; {t("Global_Button_Text_New")} &nbsp;
                    </button>) : (<span />)}

                    <DropDownList
                        label={t("Global_Label_Text_Language")}
                        name="language"
                        textField="name"
                        value={selectedLenguage}
                        loading={loading}
                        dataItemKey="languageId"
                        style={{ width: '200px' }}
                        onChange={e => setSelectedLenguage(e.value)}
                        data={languages}
                    />
                </div>
                <div>

                    <Grid
                        data={additiveAlternativeIDs}
                        editField={editField}
                        total={additiveAlternativeIDs.length}
                        pageable={false}
                        scrollable={'none'}
                        cellRender={GridCustomLoadingCell}
                        onItemChange={itemChange}
                    >
                        {isAdmin ? (<Column cell={CommandCell} width="120px" />) : (<span />)}
                        <Column field="alternativeName" title={t("Additive_AlternativeName_Label")} />

                    </Grid>
                </div>
            </div>
        </div>
    );




}
