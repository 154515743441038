import * as React from 'react';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import RawMaterialService from "../../services/RawMaterials.service";
import { useTranslation } from "react-i18next";
import AnalyticComponentsDropDownCell from '../../components/DropDownListCustomAnalyticalComponents';
import AnalyticComponentsService from "../../services/AnalyticComponents.service";
import checkboxCellGrid from "../../components/GridCustomCheckboxCell";
import { PanelCustomLoader } from "../../components/PanelCustomLoader";

export default function RawMaterialStatements(props) {

    const { t } = useTranslation();
    const editField = "inEdit";
    const isAdmin = props.isAdmin;

    const [rawMaterialsStatements, setRawMaterialsStatements] = React.useState([]);
    const [loading, setLoading] = React.useState(false);

    React.useEffect(() => {

        setLoading(true);
        console.log(props.materialData);
        RawMaterialService.getRawMaterialStatements(props.materialData.rawMaterialId)
            .then(response => response.data)
            .then(json => {
                setRawMaterialsStatements(json.contract);
                setLoading(false);
            });

    }, [props.materialData]);


    const MyCommandCell = (props) => {
        const { dataItem } = props;
        const inEdit = dataItem[props.editField];

        return inEdit ? (
            <td className="k-command-cell">
                <button
                    className="k-button k-grid-save-command"
                    title={dataItem.isNew ? t("Global_Button_Text_Add") : t("Global_Button_Text_Update")}
                    onClick={() => (dataItem.isNew ? props.add(dataItem) : props.update(dataItem))}
                >
                    <span className="k-icon k-i-save" />
                </button>
                <button
                    className="k-button k-grid-cancel-command"
                    title={dataItem.isNew ? t("Global_Button_Text_Discard") : t("Global_Button_Text_Cancel")}
                    onClick={() => (dataItem.isNew ? props.discard(dataItem) : props.cancel(dataItem))}
                >
                    {dataItem.isNew ? (<span class="k-icon k-i-close" />) : (<span class="k-icon k-i-undo" />)}
                </button>
            </td>
        ) : (
                <td className="k-command-cell">
                    <button
                        className="k-primary k-button k-grid-edit-command"
                        title={t("Global_Button_Text_Edit")}
                        onClick={() => props.edit(dataItem)}>
                        <span className="k-icon k-i-edit" />
                    </button>
                    <button
                        className="k-button k-grid-remove-command"
                        title={t("Global_Button_Text_Remove")}
                        onClick={() => {
                            props.remove(dataItem);
                        }}
                    >
                        <span className="k-icon k-i-close" />
                    </button>
                </td>
            );
    };

    const remove = dataItem => {
        dataItem.inEdit = false;
        setLoading(true);
        RawMaterialService.deleteRawMaterialStatements(props.materialData.rawMaterialId, dataItem.rawMaterialStatementId)
            .then(response => {
                RawMaterialService.getRawMaterialStatements(props.materialData.rawMaterialId)
                    .then(response => response.data.contract)
                    .then(json => {
                        setRawMaterialsStatements(json);
                        setLoading(false);
                    });
            })
    };

    const add = dataItem => {
        dataItem.inEdit = true;

        setLoading(true);
        console.log(dataItem);
        RawMaterialService.saveRawMaterialStatement(props.materialData.rawMaterialId, {
            "analyticComponentId": dataItem.analyticComponentId,
            "rawMaterialId": props.materialData.rawMaterialId,
            "isMandatory": dataItem.isMandatory,
            "minValue": dataItem.minValue,
            "maxValue": dataItem.maxValue
        })
            .then(response => {

                var data = rawMaterialsStatements.map(item => (item.rawMaterialStatementId === dataItem.rawMaterialStatementId) ?
                    { ...item, rawMaterialStatementId: response.data.contract.rawMaterialStatementId, inEdit: false, isNew: false } : item);
                setRawMaterialsStatements(data);
                setLoading(false);
            });

    };

    const update = dataItem => {
        dataItem.inEdit = false;

        RawMaterialService.updateRawMaterialStatement(props.materialData.rawMaterialId, dataItem.rawMaterialStatementId, {
            "rawMaterialStatementId": dataItem.rawMaterialStatementId,
            "rawMaterialId": props.materialData.rawMaterialId,
            "analyticComponentId": dataItem.analyticComponentId,
            "isMandatory": dataItem.isMandatory ?? false,
            "minValue": dataItem.minValue,
            "maxValue": dataItem.maxValue
        })
            .then(response => {

                RawMaterialService.getRawMaterialStatements(props.materialData.rawMaterialId)
                    .then(response => response.data)
                    .then(json => {
                        setRawMaterialsStatements(json.contract);
                        setLoading(false);
                    });
            })

    };

    const discard = dataItem => {
        setRawMaterialsStatements(rawMaterialsStatements.filter(item => item.rawMaterialStatementId !== dataItem.rawMaterialStatementId));
    };

    const cancel = dataItem => {
        setRawMaterialsStatements(rawMaterialsStatements.filter(rawMaterialsStatements.map(item => (item.rawMaterialStatementId === dataItem.rawMaterialStatementId) ? { ...item, inEdit: false } : item)));
    };

    const enterEdit = dataItem => {
        setRawMaterialsStatements(rawMaterialsStatements.map(item => (item.rawMaterialStatementId === dataItem.rawMaterialStatementId) ? { ...item, inEdit: true } : item));
    };

    const itemChange = event => {
        const data = rawMaterialsStatements.map(item => item.rawMaterialStatementId === event.dataItem.rawMaterialStatementId ? { ...item, [event.field]: event.value } : item);
        setRawMaterialsStatements(data);
    };

    const CommandCell = props => (
        <MyCommandCell
            {...props}
            edit={enterEdit}
            remove={remove}
            add={add}
            discard={discard}
            update={update}
            cancel={cancel}
            editField={editField}
        />
    );

    const [analyticComponents, setAnalyticComponents] = React.useState([]);

    React.useEffect(() => {

        AnalyticComponentsService.getAllAnalyticComponents(1)
            .then(response => response.data)
            .then(json => {
                setAnalyticComponents(json.contract);
            });

    }, []);

    const addNew = () => {

        const newDataItem = { isNew: true, inEdit: true, alternativeName: "", rawMaterialStatementId: Math.floor(Math.random() * 1000) + 1 };

        const data = [...rawMaterialsStatements, newDataItem];

        setRawMaterialsStatements(data);
    };

    const analyticComponentsDropDownCell = (props) => <AnalyticComponentsDropDownCell {...props} analyticComponents={analyticComponents} />

    return (
        <div>
            {loading && PanelCustomLoader}

            <div className="card">
                {isAdmin ? (<div className="card-header d-flex justify-content-between align-items-center">
                    <button
                        title={t("RawMaterials_Statements_Button_Tooltip_New")}
                        className="k-button k-primary"
                        onClick={addNew} >
                        &nbsp;<span className="k-icon k-i-plus-circle" />&nbsp; {t("Global_Button_Text_New")} &nbsp;
                            </button>
                </div>) : (<span />)}
                <div>

                    <Grid
                        itemChange={itemChange}
                        data={rawMaterialsStatements}
                        editField={editField}
                        total={rawMaterialsStatements.length}
                        pageable={false}
                        scrollable={'none'}
                        onItemChange={itemChange}
                    >
                        {isAdmin ? (<Column cell={CommandCell} width="120px" />) : (< span />)}
                        <Column field="analyticComponentId" title={t("RawMaterials_Statement_AnalyticComponentId_Label")} cell={analyticComponentsDropDownCell} />
                        <Column field="maxValue" editor="numeric" title={t("RawMaterials_Statement_MinValue_Label")} />
                        <Column field="minValue" editor="numeric" title={t("RawMaterials_Statement_MaxValue_Label")} />
                        <Column field="isMandatory" cell={checkboxCellGrid} title={t("RawMaterials_Statement_IsMandatory_Label")} />
                    </Grid>
                </div>
            </div>
        </div>
    );

}
