import * as React from 'react';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { DropDownList } from "@progress/kendo-react-dropdowns";
import AnalyticComponentsService from "../../services/AnalyticComponents.service";
import LanguagesService from "../../services/Languages.service";
import { useTranslation } from "react-i18next";
import { avaiableLangList } from '../../i18nextConf'
import { GridCustomAltIdCommandCell } from "../../components/GridCustomAltIdCommandCell";
import { GridCustomLoadingCell } from "../../components/GridCustomLoadingCell";
import { PanelCustomLoader } from "../../components/PanelCustomLoader";

export default function AnalyticComponentsAlternativeIdentifiers(props) {

    const { t } = useTranslation();
    const editField = "inEdit";
    const isAdmin = props.isAdmin;

    const [languages, setLanguages] = React.useState([]);
    const [selectedLenguage, setSelectedLenguage] = React.useState({ name: "Loading...", languageId: "" });
    const [analyticComponentsAlternativeIDs, setAnalyticComponentsAlternativeIDs] = React.useState([]);
    const [loading, setLoading] = React.useState(true);

    React.useEffect(() => {

        LanguagesService.getLanguages()
            .then(response => response.data.contract)
            .then(json => {
                setLanguages(json);

                var selected = localStorage.getItem("i18nextLng") || "en";
                var selectedLangDetails = avaiableLangList.find(lang => lang.short === selected);
                var defaultLang = json.find(lang => lang.languageId === selectedLangDetails.id);
                setSelectedLenguage(defaultLang);
            });

    }, []);

    React.useEffect(() => {

        setLoading(true);

        if (selectedLenguage.languageId !== "")
            AnalyticComponentsService.getAnalyticComponentsAlternativeIdentifiers(props.analyticComponentId, selectedLenguage.languageId)
                .then(response => response.data)
                .then(json => {
                    setAnalyticComponentsAlternativeIDs(json.contract);
                    setLoading(false);
                }).catch(enf => {
                    setAnalyticComponentsAlternativeIDs([]);
                    setLoading(false);
                });

    }, [props.analyticComponentId, selectedLenguage]);

    const remove = dataItem => {

        AnalyticComponentsService.deleteAnalyticComponentAlternativeIdentifier({
            "analyticalComponentAlternativeIdentifierId": dataItem.analyticalComponentAlternativeIdentifierId,
            "analyticalComponentId": parseInt(props.analyticComponentId)
        }).then(response => {
            AnalyticComponentsService.getAnalyticComponentsAlternativeIdentifiers(props.analyticComponentId, selectedLenguage.languageId)
                .then(response => response.data)
                .then(json => {
                    setAnalyticComponentsAlternativeIDs(json.contract);
                    setLoading(false);
                }).catch(error => {
                    setAnalyticComponentsAlternativeIDs([]);
                    setLoading(false);
                });
        });
    };

    const add = dataItem => {

        dataItem.inEdit = true;

        AnalyticComponentsService.saveAnalyticComponentAlternativeIdentifier({
            "analyticalComponentAlternativeIdentifierId": Math.floor(Math.random() * 1000) + 1,
            "analyticalComponentId": parseInt(props.analyticComponentId),
            "languageId": selectedLenguage.languageId,
            "alternativeName": dataItem.alternativeName
        })
            .then(response => {

                var data = analyticComponentsAlternativeIDs.map(item => (item.analyticalComponentAlternativeIdentifierId === dataItem.analyticalComponentAlternativeIdentifierId) ?
                    { ...item, analyticalComponentAlternativeIdentifierId: response.data.contract.analyticalComponentAlternativeIdentifierId, inEdit: false, isNew: false } : item);

                setAnalyticComponentsAlternativeIDs(data);
            });

    };

    const update = dataItem => {

        dataItem.inEdit = false;

        AnalyticComponentsService.updateAnalyticalComponentAlternativeIdentifier({
            "analyticalComponentAlternativeIdentifierId": dataItem.analyticalComponentAlternativeIdentifierId,
            "analyticalComponentId": parseInt(props.analyticComponentId),
            "languageId": dataItem.languageId,
            "alternativeName": dataItem.alternativeName
        })
            .then(response => {
                AnalyticComponentsService.getAnalyticComponentsAlternativeIdentifiers(props.analyticComponentId, selectedLenguage.languageId)
                    .then(response => response.data)
                    .then(json => {
                        setAnalyticComponentsAlternativeIDs(json.contract); setLoading(false);
                    });
            })

    };

    const discard = dataItem => {

        setAnalyticComponentsAlternativeIDs(analyticComponentsAlternativeIDs.filter(item => item.analyticalComponentAlternativeIdentifierId !== dataItem.analyticalComponentAlternativeIdentifierId));
    };

    const cancel = dataItem => {

        const itemEdit = analyticComponentsAlternativeIDs.find(item => (item.analyticalComponentAlternativeIdentifierId === dataItem.analyticalComponentAlternativeIdentifierId));

        setAnalyticComponentsAlternativeIDs(analyticComponentsAlternativeIDs.map(itemMap =>
            itemEdit.analyticalComponentAlternativeIdentifierId === itemMap.analyticalComponentAlternativeIdentifierId ?
                { ...itemMap, inEdit: false } : itemMap));

    };

    const enterEdit = dataItem => {

        setAnalyticComponentsAlternativeIDs(analyticComponentsAlternativeIDs.map(item => (item.analyticalComponentAlternativeIdentifierId === dataItem.analyticalComponentAlternativeIdentifierId) ? { ...item, inEdit: true, isNew: false } : item));
    };

    const itemChange = event => {

        const data = analyticComponentsAlternativeIDs.map(item => item.analyticalComponentAlternativeIdentifierId === event.dataItem.analyticalComponentAlternativeIdentifierId ? { ...item, [event.field]: event.value } : item);

        setAnalyticComponentsAlternativeIDs(data);

    };

    const CommandCell = props => (
        <GridCustomAltIdCommandCell
            {...props}
            edit={enterEdit}
            remove={remove}
            add={add}
            discard={discard}
            update={update}
            cancel={cancel}
            editField={editField}
        />
    );

    const addNew = () => {

        const newDataItem = { isNew: true, inEdit: true, alternativeName: "", analyticalComponentAlternativeIdentifierId: Math.floor(Math.random() * 1000) + 1 };

        const data = [newDataItem, ...analyticComponentsAlternativeIDs];

        setAnalyticComponentsAlternativeIDs(data);
    };

    return (
        <div>
            {loading && PanelCustomLoader}

            <div className="card">
                <div className="card-header d-flex justify-content-between align-items-center">
                    {isAdmin ? (<button
                        title={t("RawMaterials_AlternativeIdentifiers_Button_Tooltip_New")}
                        className="k-button k-primary"
                        onClick={addNew} >
                        &nbsp;<span className="k-icon k-i-plus-circle" />&nbsp; {t("Global_Button_Text_New")} &nbsp;
                    </button>) : (<span />)}

                    <DropDownList
                        label={t("Global_Label_Text_Language")}
                        name="language"
                        textField="name"
                        value={selectedLenguage}
                        loading={loading}
                        dataItemKey="languageId"
                        style={{ width: '200px' }}
                        onChange={e => setSelectedLenguage(e.value)}
                        data={languages}
                    />
                </div>
                <div>

                    <Grid
                        data={analyticComponentsAlternativeIDs}
                        editField={editField}
                        total={analyticComponentsAlternativeIDs.length}
                        pageable={false}
                        scrollable={'none'}
                        cellRender={GridCustomLoadingCell}
                        onItemChange={itemChange}
                    >
                        {isAdmin ? (<Column cell={CommandCell} width="120px" />) : (<span />)}
                        <Column field="alternativeName" title={t("AnalyticComponents_AlternativeName_Label")} />

                    </Grid>
                </div>
            </div>
        </div>
    );




}
