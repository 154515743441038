import React from 'react';
import RawMaterialAlternativeIdentifiers from '../../Models/RawMaterial/AlternativeIdentifiers';
import AuthService from "../../services/auth.service";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function RawMaterialAlternativeIdentifiersPage(props) {

    const { params } = props.match;
    const { t } = useTranslation();

    return (
        <div>
            <br />
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to={'/home/'}>{t("Home_Breadcrumb_Title")}</Link></li>
                    <li className="breadcrumb-item"><Link to={'/RawMaterials'}>{t("RawMaterials_Breadcrumb_Title")}</Link></li>
                    <li className="breadcrumb-item active">{params.rawMaterialId}</li>
                </ol>
            </nav>
            <br />

            <div className="card text-center">
                <div className="card-header">
                    <ul className="nav nav-tabs card-header-tabs">
                        <li className="nav-item">
                            <Link to={'/RawMaterials/' + params.rawMaterialId + '/Edit/'} className="nav-link">{t("RawMaterials_Details_Tab")}</Link>
                        </li>
                        <li className="nav-item">
                            <Link to={'/RawMaterials/' + params.rawMaterialId + '/Edit/AlternativeIdentifiers'} className="nav-link active">{t("RawMaterials_AlternativeIdentifiers_Tab")}</Link>
                        </li>
                        <li className="nav-item">
                            <Link to={'/RawMaterials/' + params.rawMaterialId + '/Edit/Statements'} className="nav-link">{t("RawMaterials_Statements_Tab")}</Link>
                        </li>
                        <li className="nav-item">
                            <Link to={'/RawMaterials/' + params.rawMaterialId + '/Edit/GroupStatements'} className="nav-link">{t("RawMaterials_GroupStatements_Tab")}</Link>
                        </li>
                    </ul>
                </div>
                <div className="card-body">
                    <RawMaterialAlternativeIdentifiers
                        rawMaterialId={params.rawMaterialId}
                        isAdmin={AuthService.isUSerAdmin()}
                    />
                </div>
            </div>
        </div >
    );

}
