import React from 'react';
import AnalyticalComponentAlternativeIdentifiers from '../../Models/AnalyticComponent/AlternativeIdentifiers';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import AuthService from "../../services/auth.service";

export default function AnalyticComponentsAlternativeIdentifiersPage(props) {

    const { params } = props.match;
    const { t } = useTranslation();

    return (
        <div>
            <br />
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to={'/home/'}>{t("Home_Breadcrumb_Title")}</Link></li>
                    <li className="breadcrumb-item"><Link to={'/AnalyticComponents'}>{t("AnalyticalComponents_Breadcrumb_Title")}</Link></li>
                    <li className="breadcrumb-item active">{params.analyticComponentId}</li>
                </ol>
            </nav>

            <div className="card text-center">
                <div className="card-header">
                    <ul className="nav nav-tabs card-header-tabs">
                        <li className="nav-item">
                            <Link to={'/AnalyticComponents/' + params.analyticComponentId + '/Edit/'} className="nav-link">{t("AnalyticalComponents_Details_Tab")}</Link>
                        </li>
                        <li className="nav-item">
                            <Link to={'/AnalyticComponents/' + params.analyticComponentId + '/Edit/AlternativeIdentifiers'} className="nav-link active">{t("AnalyticalComponents_AlternativeIdentifiers_Tab")}</Link>
                        </li>
                    </ul>
                </div>
                <div className="card-body">
                    <AnalyticalComponentAlternativeIdentifiers
                        analyticComponentId={params.analyticComponentId}
                        isAdmin={AuthService.isUSerAdmin()}
                    />
                </div>
            </div>

        </div >
    );

}
