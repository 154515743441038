import * as React from 'react';
import { DropDownList } from '@progress/kendo-react-dropdowns';

export default function FunctionalGroupsDropDownCell(props) {

    const handleChange = (e) => {
        props.onChange({
            dataItem: props.dataItem,
            field: props.field,
            syntheticEvent: e.syntheticEvent,
            value: e.target.value
        });
    }
    const { dataItem } = props;

    return (
        <td>
            {dataItem.inEdit ? (
                <DropDownList
                    style={{ width: "200px" }}
                    data={props.functionalGroups}
                    onChange={handleChange}
                    value={dataItem.functionalGroup}
                    textField='name'
                    dataItemKey="functionalGroupId"
                />
            ) : (
                    props.functionalGroups.find(c => (c.additiveCategoryId === dataItem.additiveCategoryId)
                        && (c.functionalGroupId === dataItem.functionalGroupId)).name
                )}
        </td>
    );
}
