import axios from 'axios';
import authHeader from './auth-header';
import AuthService from './auth.service'
const API_URL = 'https://flbasisapi.agrifoodatsolutions.com';
const axiosApiInstanceForRawMaterials = axios.create();

axiosApiInstanceForRawMaterials.interceptors.response.use(response => {
    return response;
}, async error => {
    const originalRequest = error.config;
    if (error.response.status === 401) {

        originalRequest._retry = true;
        await AuthService.relogin();
        originalRequest.headers = authHeader();
        return axiosApiInstanceForRawMaterials(originalRequest);
    }

    if (error.response.status === 404) {
        console.log(error);
    }

    return Promise.reject(error);
});

class RawMaterialsService {

    saveRawMaterial(RawMaterial) {
        return axiosApiInstanceForRawMaterials.post(API_URL + '/v1/rawMaterials', RawMaterial, { headers: authHeader() });
    }

    updateRawMaterial(RawMaterial) {
        return axiosApiInstanceForRawMaterials.put(API_URL + '/v1/rawMaterials/' + RawMaterial.rawMaterialId, RawMaterial, { headers: authHeader() });
    }

    deleteRawMaterial(RawMaterial) {
        return axiosApiInstanceForRawMaterials.delete(API_URL + '/v1/rawMaterials/' + RawMaterial.rawMaterialId, { headers: authHeader() });
    }

    getRawMaterial(RawMaterialID) {
        return axiosApiInstanceForRawMaterials.get(API_URL + '/v1/rawMaterials/' + RawMaterialID, { headers: authHeader() });
    }

    getRawMaterialStatements(RawMaterialID) {
        return axiosApiInstanceForRawMaterials.get(API_URL + '/v1/rawMaterials/' + RawMaterialID + "/statements", { headers: authHeader() });
    }

    getRawMaterialGroupStatements(RawMaterialGroupId) {
        return axiosApiInstanceForRawMaterials.get(API_URL + '/v1/rawMaterialGroups/' + RawMaterialGroupId + "/statements", { headers: authHeader() });
    }

    saveRawMaterialStatement(RawMaterialID, RawMaterialStatement) {
        return axiosApiInstanceForRawMaterials.post(API_URL + '/v1/rawMaterials/' + RawMaterialID + "/statements", RawMaterialStatement, { headers: authHeader() });
    }

    updateRawMaterialStatement(RawMaterialID, RawMaterialStatementId, RawMaterialStatement) {
        return axiosApiInstanceForRawMaterials.put(API_URL + '/v1/rawMaterials/' + RawMaterialID + "/statements/" + RawMaterialStatementId, RawMaterialStatement, { headers: authHeader() });
    }

    deleteRawMaterialStatements(RawMaterialID, RawMaterialStatementId) {
        return axiosApiInstanceForRawMaterials.delete(API_URL + '/v1/rawMaterials/' + RawMaterialID + "/statements/" + RawMaterialStatementId, { headers: authHeader() });
    }

    getGroups(languageId) {
        return axiosApiInstanceForRawMaterials.get(API_URL + '/v1/rawMaterialGroups?languageId=' + languageId, { headers: authHeader() });
    }

    getrawMaterials(PageNumber, PageSize, Lang) {
        return axiosApiInstanceForRawMaterials.get(API_URL + '/v1/rawMaterials/page?pageNumber=' + PageNumber + '&pageSize=' + PageSize + '&languageId=' + Lang, { headers: authHeader() }).catch(function (error) {
            if (error.response) {
                // Request made and server responded
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
            } else if (error.request) {
                // The request was made but no response was received
                console.log(error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
            }

        });
    }

    getrawMaterialsOrderedBy(PageNumber, PageSize, OrderBy, Lang) {
        return axiosApiInstanceForRawMaterials.get(API_URL + '/v1/rawMaterials/page?pageNumber=' + PageNumber + '&pageSize=' + PageSize + '&OrderBy=' + OrderBy + '&languageId=' + Lang, { headers: authHeader() }).catch(function (error) {
            if (error.response) {
                // Request made and server responded
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
            } else if (error.request) {
                // The request was made but no response was received
                console.log(error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
            }

        });
    }

    getrawMaterialsOrderedByAndFiltered(PageNumber, PageSize, OrderBy, Lang, Filter) {
        return axiosApiInstanceForRawMaterials.get(API_URL + '/v1/rawMaterials/page?pageNumber=' + PageNumber + '&pageSize=' + PageSize + '&OrderBy=' + OrderBy + '&languageId=' + Lang + '&filter=' + Filter, { headers: authHeader() }).catch(function (error) {
            if (error.response) {
                // Request made and server responded
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
            } else if (error.request) {
                // The request was made but no response was received
                console.log(error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
            }

        });
    }

    getrawMaterialsLanguages(rawMaterialID) {
        return axiosApiInstanceForRawMaterials.get(API_URL + '/v1/rawMaterials/' + rawMaterialID + '/localizations', { headers: authHeader() }).catch(function (error) {
            if (error.response) {
                // Request made and server responded
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
            } else if (error.request) {
                // The request was made but no response was received
                console.log(error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
            }

        });
    }

    getrawMaterialsAlternativeIdentifiers(rawMaterialID, languageId) {
        return axiosApiInstanceForRawMaterials.get(API_URL + '/v1/rawMaterials/' + rawMaterialID + '/alternativeIdentifiers/?languageId=' + languageId, { headers: authHeader() }).catch(function (error) {
            if (error.response) {
                // Request made and server responded
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
            } else if (error.request) {
                // The request was made but no response was received
                console.log(error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
            }

        });
    }

    getRawMaterialTranslation(RawMaterialID, LanguageID) {
        return axiosApiInstanceForRawMaterials.get(API_URL + '/v1/rawMaterials/' + RawMaterialID + '/localizations/' + LanguageID, { headers: authHeader() }).catch(function (error) {
            if (error.response) {
                // Request made and server responded
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
                //alert(error.response.data.message);
            } else if (error.request) {
                // The request was made but no response was received
                console.log(error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
            }

        });
    }

    saveRawMaterialLocalization(RawMaterialLocalization) {
        return axiosApiInstanceForRawMaterials.post(API_URL + '/v1/rawMaterials/' + RawMaterialLocalization.rawMaterialId + '/localizations', RawMaterialLocalization, { headers: authHeader() }).catch(function (error) {
            if (error.response) {
                // Request made and server responded
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
                alert(error.response.data.message);
            } else if (error.request) {
                // The request was made but no response was received
                console.log(error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
            }
        });

    }

    updateRawMaterialLocalization(RawMaterialLocalization) {
        return axiosApiInstanceForRawMaterials.put(API_URL + '/v1/rawMaterials/' + RawMaterialLocalization.rawMaterialId + '/localizations/' + RawMaterialLocalization.languageId, RawMaterialLocalization, { headers: authHeader() }).catch(function (error) {
            if (error.response) {
                // Request made and server responded
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
                alert(error.response.data.message);
            } else if (error.request) {
                // The request was made but no response was received
                console.log(error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
            }
        });
    }

    saverawMaterialAlternativeIdentifier(RawMaterialAlternativeIdentifiers) {
        return axiosApiInstanceForRawMaterials.post(API_URL + '/v1/rawMaterials/' + RawMaterialAlternativeIdentifiers.rawMaterialId + '/alternativeIdentifiers', RawMaterialAlternativeIdentifiers, { headers: authHeader() });
    }

    deleteRawMaterialAlternativeIdentifier(RawMaterialAlternativeIdentifiers) {
        return axiosApiInstanceForRawMaterials.delete(API_URL + '/v1/rawMaterials/' + RawMaterialAlternativeIdentifiers.rawMaterialId + '/alternativeIdentifiers/' + RawMaterialAlternativeIdentifiers.rawMaterialAlternativeIdentifierId, { headers: authHeader() }).catch(function (error) {
            if (error.response) {
                // Request made and server responded
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
                alert(error.response.data.message);
            } else if (error.request) {
                // The request was made but no response was received
                console.log(error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
            }

        });
    }

    updateRawMaterialAlternativeIdentifier(RawMaterialAlternativeIdentifiers) {
        return axiosApiInstanceForRawMaterials.put(API_URL + '/v1/rawMaterials/' + RawMaterialAlternativeIdentifiers.rawMaterialId + '/alternativeIdentifiers/' + RawMaterialAlternativeIdentifiers.rawMaterialAlternativeIdentifierId, RawMaterialAlternativeIdentifiers, { headers: authHeader() }).catch(function (error) {
            if (error.response) {
                // Request made and server responded
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
                alert(error.response.data.message);
            } else if (error.request) {
                // The request was made but no response was received
                console.log(error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
            }

        });
    }
}

export default new RawMaterialsService();
