import * as React from 'react';
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { CustomCheckbox, CustomInput, CustomTextArea } from "../../components/CustomComponents"
import AnalyticComponents from "../../services/AnalyticComponents.service";
import { useTranslation } from "react-i18next";
import LanguagesService from "../../services/Languages.service";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { avaiableLangList } from '../../i18nextConf'

export default function AnalyticComponentInsert(props) {

    const { t } = useTranslation();

    const [languages, setLanguages] = React.useState([]);
    const [selectedLenguage, setSelectedLenguage] = React.useState({ name: "Loading...", languageId: "es-ES" });

    const [analyticComponent, setAnalyticComponent] = React.useState({
        "analyticComponentId": 0,
        "name": "",
        "remarks": "",
        "hasAlternativeIdentifier": false
    });

    React.useEffect(() => {

        LanguagesService.getLanguages()
            .then(response => response.data.contract)
            .then(json => {
                setLanguages(json);

                var selected = localStorage.getItem("i18nextLng") || "en";
                var selectedLangDetails = avaiableLangList.find(lang => lang.short === selected);
                var defaultLang = json.find(lang => lang.languageId === selectedLangDetails.id);
                setSelectedLenguage(defaultLang);
            });

    }, []);


    const requiredValidator = (value) => {
        return value ? "" : "This field is required";
    }

    const handleSubmit = (dataItem) => {

        AnalyticComponents.saveAnalyticComponent({
            "analyticComponentId": parseInt(dataItem.analyticComponentId),
            "name": dataItem.name,
            "remarks": dataItem.remarks,
            "hasAlternativeIdentifier": dataItem.hasAlternativeIdentifier
        }).then(response => response.data.contract)
            .then(
                json => {
                    AnalyticComponents.saveAnalyticalComponentLocalization({
                        "analyticComponentId": parseInt(json.analyticComponentId),
                        "languageId": selectedLenguage.languageId,
                        "name": dataItem.name,
                        "remarks": dataItem.remarks

                    }).then(response => response.data.contract)
                        .then(
                            end => {
                                return window.location.href = "/AnalyticComponents/" + end.analyticComponentId + "/Edit";
                            }
                        );
                }
            );

    }



    return (
        <div>
            <Form
                onSubmit={handleSubmit}
                initialValues={analyticComponent}
                key={JSON.stringify(analyticComponent)}
                render={(formRenderProps) => (
                    <FormElement>
                        <fieldset>
                            <div className="card">
                                <div className="card-header d-flex justify-content-between align-items-center">

                                    <b>{t("AnalyticComponents_Details_GeneralData_Title")}</b>

                                    <DropDownList
                                        label={t("Global_Label_Text_Language")}
                                        name="language"
                                        textField="name"
                                        value={selectedLenguage}
                                        dataItemKey="languageId"
                                        style={{ width: '200px' }}
                                        onChange={e => setSelectedLenguage(e.value)}
                                        data={languages}
                                    />

                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-sm-3">
                                            <Field
                                                label={t("AnalyticComponents_ID_Label")}
                                                name={'analyticComponentId'}
                                                component={CustomInput}
                                                onChange={e => setAnalyticComponent({ ...analyticComponent, name: e.target.value })}
                                            />
                                        </div>
                                        <div className="col-sm-6">
                                            <Field
                                                label={t("AnalyticComponents_Name_Label")}
                                                name={'name'}
                                                component={CustomInput}
                                                validator={[requiredValidator]}
                                                onChange={e => setAnalyticComponent({ ...analyticComponent, name: e.target.value })}
                                            />
                                        </div>
                                        <div className="col-sm-3">
                                            <Field
                                                label={t("AnalyticComponents_isDisabled_Label")}
                                                name={'isDisabled'}
                                                onChange={e => setAnalyticComponent({ ...analyticComponent, name: e.target.value })}
                                                component={CustomCheckbox} />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <Field
                                                label={t("AnalyticComponents_Remarks_Label")}
                                                name={'remarks'}
                                                component={CustomTextArea}
                                                maxLength={1000}
                                                style={{ width: "100%" }}
                                                onChange={e => setAnalyticComponent({ ...analyticComponent, name: e.target.value })}
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-3">
                                            <Field
                                                label={t("AnalyticComponents_HasAlternativeIdentifier_Label")}
                                                name={'hasAlternativeIdentifier'}
                                                onChange={e => setAnalyticComponent({ ...analyticComponent, name: e.target.value })}
                                                component={CustomCheckbox} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </fieldset>
                        <br />
                        <div className="float-sm-right k-form-buttons">
                            <button
                                type={'submit'}
                                disabled={!formRenderProps.allowSubmit}
                                className="k-button"
                            >
                                <span className="k-icon k-i-save" /> &nbsp; {t("Global_Button_Text_Save")}
                            </button>
                        </div>
                    </FormElement>)}
            />
        </div>
    );
}
