import React from 'react';
import Edit from '../../Models/FunctionalGroups/Edit';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import AuthService from "../../services/auth.service";

export default function Details(props) {

    const { params } = props.match;
    const { t } = useTranslation();

    return (
        <div>
            <br />
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to={'/home/'}>{t("Home_Breadcrumb_Title")}</Link></li>
                    <li className="breadcrumb-item"><Link to={'/FunctionalGroups'}>{t("FunctionalGroups_Breadcrumb_Title")}</Link></li>
                    <li className="breadcrumb-item active">{params.functionalGroupId}</li>
                </ol>
            </nav>


            <Edit
                functionalGroupId={params.functionalGroupId}
                isAdmin={AuthService.isUSerAdmin()}
            />

            <br />
        </div >
    );


}
