import axios from 'axios';
import authHeader from './auth-header';
import AuthService from './auth.service'
const API_URL = 'https://flbasisapi.agrifoodatsolutions.com';
const axiosApiInstanceForAnalyticComponents = axios.create();

axiosApiInstanceForAnalyticComponents.interceptors.response.use(response => {
    return response;
}, async error => {
    const originalRequest = error.config;
    if (error.response.status === 401) {

        originalRequest._retry = true;
        await AuthService.relogin();
        originalRequest.headers = authHeader();
        return axiosApiInstanceForAnalyticComponents(originalRequest);
    }
    if (error.response.status === 404) {
        console.log(error);
    }

    return Promise.reject(error);
});

class AnalyticComponentsService {

    saveAnalyticComponent(AnalyticComponent) {
        return axiosApiInstanceForAnalyticComponents.post(API_URL + '/v1/analyticComponents', AnalyticComponent, { headers: authHeader() });
    }

    updateAnalyticComponent(AnalyticComponent) {
        return axiosApiInstanceForAnalyticComponents.put(API_URL + '/v1/analyticComponents/' + AnalyticComponent.analyticComponentId, AnalyticComponent, { headers: authHeader() });
    }

    deleteAnalyticComponent(AnalyticComponent) {
        return axiosApiInstanceForAnalyticComponents.delete(API_URL + '/v1/analyticComponents/' + AnalyticComponent.analyticComponentId, { headers: authHeader() });
    }

    getAnalyticComponent(analyticComponentID) {
        return axiosApiInstanceForAnalyticComponents.get(API_URL + '/v1/analyticComponents/' + analyticComponentID, { headers: authHeader() });
    }

    getAllAnalyticComponents(PageNumber) {
        return axiosApiInstanceForAnalyticComponents.get(API_URL + '/v1/analyticComponents/page?pageNumber=' + PageNumber + '&pageSize=100', { headers: authHeader() });
    }

    getAnalyticComponents(PageNumber, PageSize, languageId) {
        return axiosApiInstanceForAnalyticComponents.get(API_URL + '/v1/analyticComponents/page?pageNumber=' + PageNumber + '&pageSize=' + PageSize + '&languageId=' + languageId, { headers: authHeader() });
    }

    getAnalyticComponentsOrderBy(PageNumber, PageSize, OrderBy, languageId) {
        return axiosApiInstanceForAnalyticComponents.get(API_URL + '/v1/analyticComponents/page?pageNumber=' + PageNumber + '&pageSize=' + PageSize + '&orderBy=' + OrderBy + '&languageId=' + languageId, { headers: authHeader() });
    }

    getAnalyticComponentLanguages(AnalyticComponentID) {
        return axiosApiInstanceForAnalyticComponents.get(API_URL + '/v1/analyticComponents/' + AnalyticComponentID + '/localizations', { headers: authHeader() });
    }

    getAnalyticComponentsAlternativeIdentifiers(analyticComponentID, languageId) {
        return axiosApiInstanceForAnalyticComponents.get(API_URL + '/v1/analyticComponents/' + analyticComponentID + '/alternativeIdentifiers?languageId=' + languageId, { headers: authHeader() });
    }

    getRawMaterialTranslation(analyticComponentID, LanguageID) {
        return axiosApiInstanceForAnalyticComponents.get(API_URL + '/v1/analyticComponents/' + analyticComponentID + '/localizations/' + LanguageID, { headers: authHeader() });
    }

    saveAnalyticalComponentLocalization(analyticComponentLocalization) {
        return axiosApiInstanceForAnalyticComponents.post(API_URL + '/v1/analyticComponents/' + analyticComponentLocalization.analyticComponentId + '/localizations', analyticComponentLocalization, { headers: authHeader() });
    }

    updateAnalyticalCoponentLocalization(analyticComponentLocalization) {
        return axiosApiInstanceForAnalyticComponents.put(API_URL + '/v1/analyticComponents/' + analyticComponentLocalization.analyticComponentId + '/localizations/' + analyticComponentLocalization.languageId, analyticComponentLocalization, { headers: authHeader() });
    }

    saveAnalyticComponentAlternativeIdentifier(analyticComponentAlternativeIdentifiers) {
        return axiosApiInstanceForAnalyticComponents.post(API_URL + '/v1/analyticComponents/' + analyticComponentAlternativeIdentifiers.analyticalComponentId + '/alternativeIdentifiers', analyticComponentAlternativeIdentifiers, { headers: authHeader() });
    }

    deleteAnalyticComponentAlternativeIdentifier(analyticComponentAlternativeIdentifiers) {
        return axiosApiInstanceForAnalyticComponents.delete(API_URL + '/v1/analyticComponents/' + analyticComponentAlternativeIdentifiers.analyticalComponentId + '/alternativeIdentifiers/' + analyticComponentAlternativeIdentifiers.analyticalComponentAlternativeIdentifierId, { headers: authHeader() });
    }

    updateAnalyticalComponentAlternativeIdentifier(analyticComponentAlternativeIdentifiers) {
        return axiosApiInstanceForAnalyticComponents.put(API_URL + '/v1/analyticComponents/' + analyticComponentAlternativeIdentifiers.analyticalComponentId + '/alternativeIdentifiers/' + analyticComponentAlternativeIdentifiers.analyticalComponentAlternativeIdentifierId, analyticComponentAlternativeIdentifiers, { headers: authHeader() });
    }

}

export default new AnalyticComponentsService();
