import * as React from 'react';
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import AnalyticComponents from "../../services/AnalyticComponents.service";
import LanguagesService from "../../services/Languages.service";
import { CustomCheckbox, CustomInput, CustomTextArea } from "../../components/CustomComponents"
import { useTranslation } from "react-i18next";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { avaiableLangList } from '../../i18nextConf'

export default function AnalyticComponentEdit(props) {

    const { t } = useTranslation();
    const isAdmin = props.isAdmin;


    const [analyticComponentLang, setAnalyticComponentLang] = React.useState({
        "analyticComponentId": props.analyticComponentId,
        "name": "",
        "remarks": "",
        "hasAlternativeIdentifier": false
    });

    const [analyticComponent, setAnalyticComponent] = React.useState({
        "analyticComponentId": 0,
        "name": "",
        "remarks": "",
        "hasAlternativeIdentifier": false
    });

    const [editableAnalyticComponent, setEditableAnalyticComponent] = React.useState({
        "analyticComponentId": 0,
        "name": "",
        "language": "",
        "remarks": "",
        "hasAlternativeIdentifier": false
    });

    const [loading, setLoading] = React.useState(true);
    const [newResource, setNewResource] = React.useState(false);
    const [languages, setLanguages] = React.useState([]);
    const [selectedLenguage, setSelectedLenguage] = React.useState({});

    React.useEffect(() => {
        AnalyticComponents.getAnalyticComponent(props.analyticComponentId)
            .then(response => response.data.contract)
            .then(analyticComponent => {
                setAnalyticComponent({
                    "analyticComponentId": analyticComponent.analyticComponentId,
                    "name": analyticComponent.name,
                    "remarks": analyticComponent.remarks || "",
                    "hasAlternativeIdentifier": analyticComponent.hasAlternativeIdentifier
                });
                setLoading(false);
            });
    }, [props.analyticComponentId]);

    React.useEffect(() => {

        LanguagesService.getLanguages()
            .then(response => response.data.contract)
            .then(json => {
                setLanguages(json);

                var selected = localStorage.getItem("i18nextLng") || "en";
                var selectedLangDetails = avaiableLangList.find(lang => lang.short === selected);
                var defaultLang = json.find(lang => lang.languageId === selectedLangDetails.id);
                setSelectedLenguage(defaultLang);
            });

    }, []);

    React.useEffect(() => {
        if (selectedLenguage.languageId !== undefined)
            AnalyticComponents.getRawMaterialTranslation(props.analyticComponentId, selectedLenguage.languageId)
                .then(response => response.data)
                .then(json => {
                    setAnalyticComponentLang({
                        "analyticComponentId": parseInt(props.analyticComponentId),
                        "language": selectedLenguage.languageId,
                        "name": json.contract.name,
                        "description": json.contract.description,
                        "remarks": json.contract.remarks,
                    });
                    setNewResource(false);
                    setLoading(false);
                })
                .catch(error => {
                    setAnalyticComponentLang({
                        "analyticComponentId": parseInt(props.analyticComponentId),
                        "language": selectedLenguage.languageId,
                        "name": "",
                        "description": "",
                        "remarks": "",
                        "exists": false
                    });
                    setNewResource(true);
                    setLoading(false);
                });
    }, [analyticComponent, selectedLenguage, props.analyticComponentId]);

    React.useEffect(() => {
        setEditableAnalyticComponent({
            "analyticComponentId": parseInt(props.analyticComponentId),
            "name": analyticComponentLang.name,
            "language": analyticComponentLang.language,
            "remarks": analyticComponentLang.remarks,
            "hasAlternativeIdentifier": analyticComponent.hasAlternativeIdentifier
        });

    }, [analyticComponentLang, selectedLenguage, analyticComponent.hasAlternativeIdentifier, props.analyticComponentId]);

    const requiredValidator = (value) => {
        return value ? "" : "This field is required";
    }

    const handleSubmit = (dataItem) => {

        AnalyticComponents.updateAnalyticComponent({
            "analyticComponentId": parseInt(dataItem.analyticComponentId),
            "name": analyticComponent.name,
            "remarks": analyticComponent.remarks,
            "hasAlternativeIdentifier": dataItem.hasAlternativeIdentifier
        });

        if (newResource) {
            AnalyticComponents.saveAnalyticalComponentLocalization({
                "analyticComponentId": parseInt(dataItem.analyticComponentId),
                "languageId": selectedLenguage.languageId,
                "name": dataItem.name,
                "remarks": dataItem.remarks || ""
            });
            setNewResource(false);
        }
        else {
            AnalyticComponents.updateAnalyticalCoponentLocalization({
                "analyticComponentId": parseInt(dataItem.analyticComponentId),
                "languageId": selectedLenguage.languageId,
                "name": dataItem.name,
                "remarks": dataItem.remarks || ""
            });
        }


        setAnalyticComponent(dataItem);
    }

    return (
        <div>
            <Form
                onSubmit={handleSubmit}
                initialValues={editableAnalyticComponent}
                key={JSON.stringify(editableAnalyticComponent)}
                render={(formRenderProps) => (
                    <FormElement>
                        <fieldset>
                            <div className="card">
                                <div className="card-header d-flex justify-content-between align-items-center">

                                    <b>{t("AnalyticComponents_Details_GeneralData_Title")}</b>

                                    <DropDownList
                                        label={t("Global_Label_Text_Language")}
                                        name="language"
                                        loading={loading}
                                        textField="name"
                                        value={selectedLenguage}
                                        dataItemKey="languageId"
                                        style={{ width: '200px' }}
                                        onChange={e => setSelectedLenguage(e.value)}
                                        data={languages}
                                    />

                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-sm-3">
                                            <Field
                                                label={t("AnalyticComponents_ID_Label")}
                                                loading={loading}
                                                disabled={true}
                                                name={'analyticComponentId'}
                                                component={CustomInput}
                                                onChange={e => setAnalyticComponent({ ...analyticComponent, name: e.target.value })}
                                            />
                                        </div>
                                        <div className="col-sm-6">
                                            <Field
                                                label={t("AnalyticComponents_Name_Label")}
                                                name={'name'}
                                                disabled={!isAdmin}
                                                loading={loading}
                                                component={CustomInput}
                                                validator={[requiredValidator]}
                                                onChange={e => setAnalyticComponent({ ...analyticComponent, name: e.target.value })}
                                            />
                                        </div>
                                        <div className="col-sm-3">
                                            <Field
                                                label={t("AnalyticComponents_isDisabled_Label")}
                                                loading={loading}
                                                disabled={!isAdmin}
                                                name={'isDisabled'}
                                                onChange={e => setAnalyticComponent({ ...analyticComponent, name: e.target.value })}
                                                component={CustomCheckbox} />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <Field
                                                label={t("AnalyticComponents_Remarks_Label")}
                                                name={'remarks'}
                                                disabled={!isAdmin}
                                                loading={loading}
                                                component={CustomTextArea}
                                                maxLength={1000}
                                                style={{ width: "100%" }}
                                                onChange={e => setAnalyticComponent({ ...analyticComponent, name: e.target.value })}
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-3">
                                            <Field
                                                label={t("AnalyticComponents_HasAlternativeIdentifier_Label")}
                                                loading={loading}
                                                disabled={!isAdmin}
                                                name={'hasAlternativeIdentifier'}
                                                onChange={e => setAnalyticComponent({ ...analyticComponent, name: e.target.value })}
                                                component={CustomCheckbox} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </fieldset>
                        <br />
                        {isAdmin ? (<div className="float-sm-right k-form-buttons">
                            <button
                                type={'submit'}
                                disabled={!formRenderProps.allowSubmit}
                                className="k-button"
                            >
                                <span className="k-icon k-i-save" /> &nbsp; {t("Global_Button_Text_Save")}
                            </button>
                        </div>) : (<span />)}
                    </FormElement>)}
            />
        </div>
    );
}
