import axios from 'axios';
import authHeader from './auth-header';
import AuthService from './auth.service'
const API_URL = 'https://flbasisapi.agrifoodatsolutions.com';
const axiosApiInstanceForLanguages = axios.create();

axiosApiInstanceForLanguages.interceptors.response.use(response => {
    return response;
}, async error => {

    const originalRequest = error.config;

    if (error.response.status === 401) {

        originalRequest._retry = true;
        await AuthService.relogin();
        originalRequest.headers = authHeader();
        return axiosApiInstanceForLanguages(originalRequest);
    }

    if (error.response.status === 404) {
        console.log(error);
    }

    return Promise.reject(error);
});

class LanguagesService {

    save(Language) {
        return axiosApiInstanceForLanguages.post(API_URL + '/v1/languages', Language, { headers: authHeader() }).catch(function (error) {
            if (error.response) {
                // Request made and server responded
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
                alert(error.response.data.message);
            } else if (error.request) {
                // The request was made but no response was received
                console.log(error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
            }

        });
    }

    update(Language) {
        return axiosApiInstanceForLanguages.put(API_URL + '/v1/languages/' + Language.languageId, Language, { headers: authHeader() }).catch(function (error) {
            if (error.response) {
                // Request made and server responded
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
                alert(error.response.data.message);
            } else if (error.request) {
                // The request was made but no response was received
                console.log(error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
            }

        });
    }

    delete(Language) {
        return axiosApiInstanceForLanguages.delete(API_URL + '/v1/languages/' + Language.languageId, { headers: authHeader() }).catch(function (error) {
            if (error.response) {
                // Request made and server responded
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
                alert(error.response.data.message);
            } else if (error.request) {
                // The request was made but no response was received
                console.log(error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
            }

        });
    }

    get(languageId) {
        return axiosApiInstanceForLanguages.get(API_URL + '/v1/languages/' + languageId, { headers: authHeader() }).catch(function (error) {
            if (error.response) {
                // Request made and server responded
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
                alert(error.response.data.message);
            } else if (error.request) {
                // The request was made but no response was received
                console.log(error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
            }

        });
    }

    async getLanguages() {
        return await axiosApiInstanceForLanguages.get(API_URL + '/v1/languages/', { headers: authHeader() });
    }

}

export default new LanguagesService();
