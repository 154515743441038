import axios from 'axios';
import authHeader from './auth-header';
import AuthService from './auth.service'
const API_URL = 'https://flbasisapi.agrifoodatsolutions.com';
const axiosApiInstanceForAdditives = axios.create();

axiosApiInstanceForAdditives.interceptors.response.use(response => {
    return response;
}, async error => {

    const originalRequest = error.config;

    if (error.response.status === 401) {

        originalRequest._retry = true;
        await AuthService.relogin();
        originalRequest.headers = authHeader();
        return axiosApiInstanceForAdditives(originalRequest);
    }

    if (error.response.status === 404) {
        console.log(error);
    }

    return Promise.reject(error);
});

class AdditivesService {

    save(Additive) {
        return axiosApiInstanceForAdditives.post(API_URL + '/v1/additives', Additive, { headers: authHeader() }).catch(function (error) {
            if (error.response) {
                // Request made and server responded
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
                alert(error.response.data.message);
            } else if (error.request) {
                // The request was made but no response was received
                console.log(error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
            }

        });
    }


    getLocalization(AdditiveId, LanguageId) {
        return axiosApiInstanceForAdditives.get(API_URL + '/v1/additives/' + AdditiveId + '/localizations/' + LanguageId, { headers: authHeader() });
    }

    saveLocalization(AdditiveLocalization) {
        return axiosApiInstanceForAdditives.post(API_URL + '/v1/additives/' + AdditiveLocalization.additiveId + '/localizations', AdditiveLocalization, { headers: authHeader() });
    }

    updateLocalization(AdditiveLocalization) {
        return axiosApiInstanceForAdditives.put(API_URL + '/v1/additives/' + AdditiveLocalization.additiveId + '/localizations/' + AdditiveLocalization.languageId, AdditiveLocalization, { headers: authHeader() });
    }

    getAlternativeIdentifiers(AdditiveId, LanguageId) {
        return axiosApiInstanceForAdditives.get(API_URL + '/v1/additives/' + AdditiveId + '/alternativeIdentifiers/?languageId=' + LanguageId, { headers: authHeader() });
    }

    saveAlternativeIdentifiers(AdditiveAlternativeIdentifier) {
        return axiosApiInstanceForAdditives.post(API_URL + '/v1/additives/' + AdditiveAlternativeIdentifier.additiveId + '/alternativeIdentifiers', AdditiveAlternativeIdentifier, { headers: authHeader() });
    }

    updateAlternativeIdentifier(AdditiveAlternativeIdentifier) {
        return axiosApiInstanceForAdditives.put(API_URL + '/v1/additives/' + AdditiveAlternativeIdentifier.additiveId + '/alternativeIdentifiers/' + AdditiveAlternativeIdentifier.additiveAlternativeIdentifierId, AdditiveAlternativeIdentifier, { headers: authHeader() });
    }

    deleteAlternativeIdentifier(AdditiveAlternativeIdentifier) {
        return axiosApiInstanceForAdditives.delete(API_URL + '/v1/additives/' + AdditiveAlternativeIdentifier.additiveId + '/alternativeIdentifiers/' + AdditiveAlternativeIdentifier.additiveAlternativeIdentifierId, { headers: authHeader() });
    }

    update(Additive) {
        return axiosApiInstanceForAdditives.put(API_URL + '/v1/additives/' + Additive.additiveId, Additive, { headers: authHeader() }).catch(function (error) {
            if (error.response) {
                // Request made and server responded
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
                alert(error.response.data.message);
            } else if (error.request) {
                // The request was made but no response was received
                console.log(error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
            }

        });
    }

    delete(Additive) {
        return axiosApiInstanceForAdditives.delete(API_URL + '/v1/additives/' + Additive.additiveId, { headers: authHeader() }).catch(function (error) {
            if (error.response) {
                // Request made and server responded
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
                alert(error.response.data.message);
            } else if (error.request) {
                // The request was made but no response was received
                console.log(error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
            }

        });
    }

    get(additiveID, languageId) {
        return axiosApiInstanceForAdditives.get(API_URL + '/v1/additives/' + additiveID + '?languageId=' + languageId, { headers: authHeader() });
    }

    getAdditiveFunctionalGroups(additiveID) {
        return axiosApiInstanceForAdditives.get(API_URL + '/v1/additives/' + additiveID + '/functionalGroups', { headers: authHeader() });
    }

    saveAdditiveFunctionalGroup(additiveID, additiveFunctionalGroup) {
        return axiosApiInstanceForAdditives.post(API_URL + '/v1/additives/' + additiveID + '/functionalGroups', additiveFunctionalGroup, { headers: authHeader() });
    }

    deleteAdditiveFunctionalGroup(additiveId, functionalGroupId) {
        return axiosApiInstanceForAdditives.delete(API_URL + '/v1/additives/' + additiveId + '/functionalGroups/' + functionalGroupId, { headers: authHeader() });
    }

    async getAdditives(PageNumber, PageSize, LanguageId) {
        return await axiosApiInstanceForAdditives.get(API_URL + '/v1/additives/page?pageNumber=' + PageNumber + "&pageSize=" + PageSize + "&languageId=" + LanguageId, { headers: authHeader() });
    }
    async getAdditivesOrderBy(PageNumber, PageSize, OrderBy, LanguageId) {
        return await axiosApiInstanceForAdditives.get(API_URL + '/v1/additives/page?pageNumber=' + PageNumber + "&pageSize=" + PageSize + "&orderBy=" + OrderBy + "&languageId=" + LanguageId, { headers: authHeader() });
    }

    async getAdditivesFiltered(PageNumber, PageSize, OrderBy, LanguageId, Filter) {
        return await axiosApiInstanceForAdditives.get(API_URL + '/v1/additives/page?pageNumber=' + PageNumber + "&pageSize=" + PageSize + "&orderBy=" + OrderBy + "&languageId=" + LanguageId + '&filter=' + Filter, { headers: authHeader() });
    }


}

export default new AdditivesService();
