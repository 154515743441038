import * as React from 'react';
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { CustomCheckbox, CustomDropDown, CustomInput, CustomIntInput, CustomTextArea } from "../../components/CustomComponents"
import LanguagesService from "../../services/Languages.service";
import RawMaterialService from "../../services/RawMaterials.service";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { useTranslation } from "react-i18next";
import { avaiableLangList } from '../../i18nextConf'

export default function RawMaterialInsert() {

    const { t } = useTranslation();

    const [rawMaterial, setRawMaterial] = React.useState({
        "rawMaterialId": "",
        "rawMaterialGroup": { name: "Select group...", rawMaterialGroupId: -1 },
        "rawMaterialBaseId": 0,
        "rawMaterialNumberId": 0,
        "name": "",
        "description": "",
        "remarks": "",
        "isDisabled": false
    });
    const [groups, setGroups] = React.useState([]);
    const [loading, setLoading] = React.useState(true);

    const [languages, setLanguages] = React.useState([]);
    const [selectedLenguage, setSelectedLenguage] = React.useState({ name: 'Spanish', languageId: "es-ES" });

    React.useEffect(() => {

        LanguagesService.getLanguages()
            .then(response => response.data.contract)
            .then(json => {
                setLanguages(json);
                var selected = localStorage.getItem("i18nextLng") || "en";
                var selectedLangDetails = avaiableLangList.find(lang => lang.short === selected);
                var defaultLang = json.find(lang => lang.languageId === selectedLangDetails.id);
                setSelectedLenguage(defaultLang);
            });

    }, [t]);

    React.useEffect(() => {

        setLoading(true);
        RawMaterialService.getGroups(selectedLenguage.languageId)
            .then(response => response.data.contract)
            .then(json => {
                // name change to see order
                json.forEach(ad => ad.name = ad.rawMaterialGroupId + " - " + ad.name);


                setGroups(json);
                setLoading(false);
            });


    }, [selectedLenguage, languages]);

    const requiredValidator = (value) => {
        return value ? "" : "This field is required";
    }

    const handleSubmit = (dataItem) => {

        if (dataItem.rawMaterialGroup.rawMaterialGroupId === -1) {

            alert("Select group before saving new Raw Material");

        }
        else {
            RawMaterialService.saveRawMaterial({
                "rawMaterialId": dataItem.rawMaterialId,
                "rawMaterialGroupId": dataItem.rawMaterialGroup.rawMaterialGroupId,
                "rawMaterialBaseId": dataItem.rawMaterialBaseId,
                "rawMaterialNumberId": dataItem.rawMaterialNumberId,
                "name": dataItem.name,
                "description": dataItem.description,
                "remarks": dataItem.remarks,
                "isDisabled": dataItem.isDisabled
            }).then(response => response.data.contract)
                .then(
                    json => {

                        RawMaterialService.saveRawMaterialLocalization({
                            "rawMaterialId": json.rawMaterialId,
                            "languageId": selectedLenguage.languageId,
                            "name": dataItem.name,
                            "description": dataItem.description,
                            "remarks": dataItem.remarks
                        }).then(resp => {

                            return window.location.href = "/RawMaterials/" + json.rawMaterialId + "/Edit";

                        }


                        );


                    }
                );
        }
    }

    return (
        <div>
            <Form
                onSubmit={handleSubmit}
                initialValues={rawMaterial}
                key={JSON.stringify(rawMaterial)}
                render={(formRenderProps) => (
                    <FormElement>
                        <fieldset>
                            <div className="card">
                                <div className="card-header d-flex justify-content-between align-items-center">

                                    <b>{t("RawMaterials_Details_GeneralData_Title")}</b>

                                    <DropDownList
                                        label={t("Global_Label_Text_Language")}
                                        name="language"
                                        textField="name"
                                        value={selectedLenguage}
                                        dataItemKey="languageId"
                                        style={{ width: '200px' }}
                                        onChange={e => setSelectedLenguage(e.value)}
                                        data={languages}
                                    />

                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-sm-9">
                                            <Field
                                                label={t("RawMaterials_Name_Label")}
                                                name={'name'}
                                                loading={loading}
                                                component={CustomInput}
                                                validator={[requiredValidator]}
                                                onChange={e => setRawMaterial({ ...rawMaterial, name: e.target.value })}
                                            />
                                        </div>

                                        <div className="col-sm-3">
                                            <Field
                                                label={t("RawMaterials_IsDisabled_Label")}
                                                name={'IsDisabled'}
                                                loading={loading}
                                                component={CustomCheckbox}
                                                onChange={e => setRawMaterial({ ...rawMaterial, name: e.target.value })}
                                            />

                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <Field
                                                label={t("RawMaterials_Group_Label")}
                                                value="rawMaterialGroupId"
                                                name={'rawMaterialGroup'}
                                                component={CustomDropDown}
                                                loading={loading}
                                                textField="name"
                                                defaultItem={rawMaterial.rawMaterialGroup}
                                                dataItemKey="rawMaterialGroupId"
                                                style={{ width: "100%" }}
                                                options={groups}
                                                onChange={e => setRawMaterial({ ...rawMaterial, name: e.target.value.rawMaterialGroupId })}
                                            />
                                        </div>
                                        <div className="col-sm-3">
                                            <Field
                                                label={t("RawMaterials_BaseID_Label")}
                                                loading={loading}
                                                name={'rawMaterialBaseId'}
                                                validator={[requiredValidator]}
                                                component={CustomIntInput}
                                                onChange={e => setRawMaterial({ ...rawMaterial, name: e.target.value })}
                                            />

                                        </div>
                                        <div className="col-sm-3">
                                            <Field
                                                label={t("RawMaterials_NumberID_Label")}
                                                loading={loading}
                                                name={'rawMaterialNumberId'}
                                                validator={[requiredValidator]}
                                                component={CustomIntInput}
                                                onChange={e => setRawMaterial({ ...rawMaterial, name: e.target.value })}
                                            />

                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <Field
                                                label={t("RawMaterials_Description_Label")}
                                                name={'description'}
                                                loading={loading}
                                                component={CustomTextArea}
                                                maxLength={1000}
                                                style={{ width: "100%" }}
                                                onChange={e => setRawMaterial({ ...rawMaterial, name: e.target.value })}
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <Field
                                                label={t("RawMaterials_Remarks_Label")}
                                                name={'remarks'}
                                                loading={loading}
                                                component={CustomTextArea}
                                                maxLength={1000}
                                                style={{ width: "100%" }}
                                                onChange={e => setRawMaterial({ ...rawMaterial, name: e.target.value })}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </fieldset>
                        <br />
                        <div className="float-sm-right k-form-buttons">
                            <button
                                type={'submit'}
                                disabled={!formRenderProps.allowSubmit}
                                className="k-button"
                            >
                                <span className="k-icon k-i-save" /> &nbsp; {t("Global_Button_Text_Save")}
                            </button>
                        </div>
                    </FormElement>)}
            />
        </div>
    );
}
