import React from 'react';
import { Home } from './pages/Home';
import AddRawMaterial from './pages/RawMaterial/AddRawMaterial';
import RawMaterialAlternativeIdentifiersPage from './pages/RawMaterial/RawMaterialAlternativeIdentifiersPage';
import RawMaterial from './pages/RawMaterial/RawMaterial';
import RawMaterialGroups from './pages/RawMaterialGroup/RawMaterialGroup';
import RawMaterialGroupsDetails from './pages/RawMaterialGroup/RawMaterialGroupDetails';
import AdditiveCategories from './pages/AdditiveCategory/AdditiveCategories';
import AdditiveCategoriesInsert from './pages/AdditiveCategory/AddAdditiveCategories';
import AdditiveCategoriesDetails from './pages/AdditiveCategory/AdditiveCategoriesDetails';
import RawMaterialGroupsStatements from './pages/RawMaterialGroup/RawMaterialGroupStatements';
import RawMaterialDetails from './pages/RawMaterial/RawMaterialDetails';
import RawMaterialStatementsGroup from './pages/RawMaterial/RawMaterialStatementsGroup';
import RawMaterialStatements from './pages/RawMaterial/RawMaterialStatements';
import AnalyticComponentsAlternativeIdentifiersPage from './pages/AnalyticComponent/AnalyticComponentsAlternativeIdentifiersPage';
import AnalyticComponent from './pages/AnalyticComponent/AnalyticComponent';
import AnalyticComponentDetails from './pages/AnalyticComponent/AnalyticComponentsDetails';
import AddAnalyticComponent from './pages/AnalyticComponent/AddAnalyticComponent';
import Additives from './pages/Additive/Additives';
import AdditivesDetails from './pages/Additive/AdditiveDetails';
import AddAdditive from './pages/Additive/AddAdditive';
import AdditiveFunctionalGroups from './pages/Additive/FunctionalGroups';
import AdditiveAlternativeIdentifiersPage from './pages/Additive/AdditiveAlternativeIdentifiersPage';
import FunctionalGroups from './pages/FunctionalGroup/List';
import FunctionalGroupsDetails from './pages/FunctionalGroup/Details';
import FunctionalGroupsAdd from './pages/FunctionalGroup/Add';
import FunctionalGroupsAdditives from './pages/FunctionalGroup/Additives';
import { Route, Switch } from "react-router-dom";
import Login from "./pages/Login";
import Profile from "./pages/Profile";
import DrawerRouterContainer from './components/DrawerRouterContainer';
import AuthService from "./services/auth.service";

import './custom.css'
import '@progress/kendo-theme-bootstrap/dist/all.css';
import version from './version'

export default function App() {

    if (process.env.NODE_ENV === "production") {
        let user = AuthService.getCurrentUser();
        if (!user)
            window.location.replace('https://auth.agrifoodatsolutions.com?service=AGRF_FL_B_WEBAPP&solution=AGRF_FL');
    }

    return (
        <div>
            <DrawerRouterContainer>
                <Switch>
                    <Route exact path={["/", "/home"]} component={Home} />
                    <Route exact path="/login" component={Login} />
                    <Route exact path="/profile" component={Profile} />
                    <Route exact path="/RawMaterials" component={RawMaterial} />
                    <Route exact path="/RawMaterialGroups" component={RawMaterialGroups} />
                    <Route exact path="/RawMaterialGroups/:rawMaterialGroupId/Edit" component={RawMaterialGroupsDetails} />
                    <Route exact path="/RawMaterialGroups/:rawMaterialGroupId/Edit/Statements" component={RawMaterialGroupsStatements} />
                    <Route exact path="/RawMaterials/:rawMaterialId/Edit" component={RawMaterialDetails} />
                    <Route exact path="/RawMaterials/Add" component={AddRawMaterial} />
                    <Route exact path="/AdditiveCategories" component={AdditiveCategories} />
                    <Route exact path="/AdditiveCategories/:additiveCategoryId/Edit" component={AdditiveCategoriesDetails} />
                    <Route exact path="/AdditiveCategories/Add" component={AdditiveCategoriesInsert} />
                    <Route exact path="/FunctionalGroups" component={FunctionalGroups} />
                    <Route exact path="/FunctionalGroups/:functionalGroupId/Edit" component={FunctionalGroupsDetails} />
                    <Route exact path="/FunctionalGroups/:functionalGroupId/Edit/Additives" component={FunctionalGroupsAdditives} />
                    <Route exact path="/FunctionalGroups/Add" component={FunctionalGroupsAdd} />
                    <Route exact path="/Additives" component={Additives} />
                    <Route exact path="/Additives/:additiveId/Edit" component={AdditivesDetails} />
                    <Route exact path="/Additives/:additiveId/Edit/FunctionalGroups" component={AdditiveFunctionalGroups} />
                    <Route exact path="/Additives/Add" component={AddAdditive} />
                    <Route exact path="/Additives/:additiveId/Edit/AlternativeIdentifiers" component={AdditiveAlternativeIdentifiersPage} />
                    <Route exact path="/AnalyticComponents" component={AnalyticComponent} />
                    <Route exact path="/AnalyticComponents/:analyticComponentId/Edit" component={AnalyticComponentDetails} />
                    <Route exact path="/AnalyticComponents/:analyticComponentId/Edit/AlternativeIdentifiers" component={AnalyticComponentsAlternativeIdentifiersPage} />
                    <Route exact path="/AnalyticComponents/Add" component={AddAnalyticComponent} />
                    <Route exact path="/RawMaterials/:rawMaterialId/Edit/AlternativeIdentifiers" component={RawMaterialAlternativeIdentifiersPage} />
                    <Route exact path="/RawMaterials/:rawMaterialId/Edit/Statements" component={RawMaterialStatements} />
                    <Route exact path="/RawMaterials/:rawMaterialId/Edit/GroupStatements" component={RawMaterialStatementsGroup} />
                </Switch>
                <div className="text-center p-3">
                    <hr style={{ align: 'center', width: '50%', marginLeft: '25%' }} />
                    <footer> {version} © Feed Labeling Basis {(new Date().getFullYear())} - Agrifood Alternative Technologies, SL &amp; Caedis Integral Solutions, SL </footer>
                </div>
            </DrawerRouterContainer>
        </div>
    );

}
