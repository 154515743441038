import * as React from 'react';
import { DropDownList } from '@progress/kendo-react-dropdowns';

export default function AdditivesDropDownCell(props) {

    const handleChange = (e) => {
        props.onChange({
            dataItem: props.dataItem,
            field: props.field,
            syntheticEvent: e.syntheticEvent,
            value: e.target.value.additiveId
        });
        console.log(e);
    }
    const { dataItem, field } = props;

    return (
        <td>
            {dataItem.inEdit ? (
                <DropDownList
                    style={{ width: "200px" }}
                    onChange={handleChange}
                    value={props.additives.find(c => c.additiveId === dataItem[field])}
                    data={props.additives}
                    textField="name"
                    dataItemKey="additiveId"
                />
            ) : (
                    (props.additives.find(c => c.additiveId === dataItem[field])).name
                )}
        </td>
    );
}
