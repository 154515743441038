import React from 'react';
import RawMaterialStatementsGroup from '../../Models/RawMaterial/StatementsGroup';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import RawMaterialService from "../../services/RawMaterials.service";

export default function RawMaterialStatements(props) {

    const { params } = props.match;
    const { t } = useTranslation();

    const [rawMaterial, setRawMaterial] = React.useState({
        "rawMaterialId": "",
        "rawMaterialGroupId": 0,
        "rawMaterialBaseId": 0,
        "rawMaterialNumberId": 0,
        "isDisabled": false
    });

    React.useEffect(() => {
        RawMaterialService.getRawMaterial(params.rawMaterialId)
            .then(response => response.data.contract)
            .then(material => {
                setRawMaterial({
                    "rawMaterialId": material.rawMaterialId,
                    "rawMaterialGroupId": material.rawMaterialGroupId,
                    "rawMaterialBaseId": material.rawMaterialBaseId,
                    "rawMaterialNumberId": material.rawMaterialNumberId,
                    "isDisabled": material.isDisabled
                });
            });
    }, [params.rawMaterialId]);

    return (
        <div>
            <br />
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to={'/home/'}>{t("Home_Breadcrumb_Title")}</Link></li>
                    <li className="breadcrumb-item"><Link to={'/RawMaterials'}>{t("RawMaterials_Breadcrumb_Title")}</Link></li>
                    <li className="breadcrumb-item active">{params.rawMaterialId}</li>
                </ol>
            </nav>
            <br />
            <div className="card text-center">
                <div className="card-header">
                    <ul className="nav nav-tabs card-header-tabs">
                        <li className="nav-item">
                            <Link to={'/RawMaterials/' + params.rawMaterialId + '/Edit/'} className="nav-link">{t("RawMaterials_Details_Tab")}</Link>
                        </li>
                        <li className="nav-item">
                            <Link to={'/RawMaterials/' + params.rawMaterialId + '/Edit/AlternativeIdentifiers'} className="nav-link">{t("RawMaterials_AlternativeIdentifiers_Tab")}</Link>
                        </li>
                        <li className="nav-item">
                            <Link to={'/RawMaterials/' + params.rawMaterialId + '/Edit/Statements'} className="nav-link">{t("RawMaterials_Statements_Tab")}</Link>
                        </li>
                        <li className="nav-item">
                            <Link to={'/RawMaterials/' + params.rawMaterialId + '/Edit/GroupStatements'} className="nav-link active">{t("RawMaterials_GroupStatements_Tab")}</Link>
                        </li>
                    </ul>
                </div>
                <div className="card-body">
                    <RawMaterialStatementsGroup
                        materialData={rawMaterial}
                    />
                </div>
            </div>
        </div >
    );

}
