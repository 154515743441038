import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Field, Form, FormElement } from "@progress/kendo-react-form";
import * as React from 'react';
import { useTranslation } from "react-i18next";
import { CustomCheckbox, CustomDateInput, CustomInput, CustomTextArea } from "../../components/CustomComponents";
import { avaiableLangList } from '../../i18nextConf';
import AdditiveService from "../../services/Additives.service";
import LanguagesService from "../../services/Languages.service";

export default function AdditiveEdit(props) {

    const { t } = useTranslation();
    const isAdmin = props.isAdmin;

    const [loading, setLoading] = React.useState(true);
    const [languages, setLanguages] = React.useState([]);
    const [newResource, setNewResource] = React.useState(false);
    const [selectedLenguage, setSelectedLenguage] = React.useState({ name: "Loading...", languageId: "" });

    const [additive, setAdditive] = React.useState({
        "additiveId": "",
        "name": "",
        "registerDescription": "",
        "authorisationDate": "",
        "authorisationExpirationDate": "",
        "registerFirstEntryDate": "",
        "remarks": "",
        "hasAlternativeIdentifier": false
    });

    const [additiveLang, setAdditiveLang] = React.useState({
        "additiveId": "",
        "name": "",
        "registerDescription": "",
        "remarks": ""
    });

    const [editableAdditive, setEditableAdditive] = React.useState({
        "additiveId": "",
        "name": "",
        "registerDescription": "",
        "authorisationDate": "",
        "authorisationExpirationDate": "",
        "registerFirstEntryDate": "",
        "remarks": "",
        "hasAlternativeIdentifier": false
    });

    React.useEffect(() => {

        LanguagesService.getLanguages()
            .then(response => response.data.contract)
            .then(json => {
                setLanguages(json);

                var selected = localStorage.getItem("i18nextLng") || "en";
                var selectedLangDetails = avaiableLangList.find(lang => lang.short === selected);
                var defaultLang = json.find(lang => lang.languageId === selectedLangDetails.id);
                setSelectedLenguage(defaultLang);
            });

    }, []);

    React.useEffect(() => {
        AdditiveService.get(props.additiveId, selectedLenguage.languageId)
            .then(response => response.data.contract)
            .then(json => {
                setAdditive({
                    "additiveId": json.additiveId,
                    "name": json.name,
                    "registerDescription": json.registerDescription,
                    "authorisationDate": new Date(json.authorisationDate),
                    "authorisationExpirationDate": new Date(json.authorisationExpirationDate),
                    "registerFirstEntryDate": new Date(json.registerFirstEntryDate),
                    "remarks": additive.remarks || "",
                    "hasAlternativeIdentifier": json.hasAlternativeIdentifier
                });
            });
    }, [props.additiveId, selectedLenguage.languageId, additive.remarks]);


    React.useEffect(() => {

        AdditiveService.getLocalization(props.additiveId, selectedLenguage.languageId)
            .then(response => response.data)
            .then(json => {
                setAdditiveLang({
                    "additiveId": additive.additiveId,
                    "name": json.contract.name,
                    "registerDescription": json.contract.registerDescription,
                    "remarks": additive.remarks || ""
                });
                setLoading(false);

            }).catch(
                () => {
                    setAdditiveLang({
                        "additiveId": additive.additiveId,
                        "name": "",
                        "registerDescription": "",
                        "remarks": ""
                    });
                    setNewResource(true);
                    setLoading(false);
                });
    }, [additive, selectedLenguage, props.additiveId]);

    React.useEffect(() => {

        setEditableAdditive({
            "additiveId": additive.additiveId,
            "name": additiveLang.name,
            "registerDescription": additiveLang.registerDescription,
            "authorisationDate": new Date(additive.authorisationDate),
            "authorisationExpirationDate": new Date(additive.authorisationExpirationDate),
            "registerFirstEntryDate": new Date(additive.registerFirstEntryDate),
            "remarks": additiveLang.remarks || "",
            "hasAlternativeIdentifier": additive.hasAlternativeIdentifier
        });
    }, [additiveLang, additive.additiveId, additive.authorisationDate, additive.authorisationExpirationDate, additive.hasAlternativeIdentifier, additive.registerFirstEntryDate]);


    const handleSubmit = (dataItem) => {

        AdditiveService.update({
            "additiveId": dataItem.additiveId,
            "name": dataItem.name,
            "registerDescription": dataItem.registerDescription,
            "authorisationDate": dataItem.authorisationDate,
            "authorisationExpirationDate": dataItem.authorisationExpirationDate,
            "registerFirstEntryDate": dataItem.registerFirstEntryDate,
            "remarks": dataItem.remarks || "",
            "hasAlternativeIdentifier": dataItem.hasAlternativeIdentifier
        });

        if (newResource)
            AdditiveService.saveLocalization({
                "additiveId": dataItem.additiveId,
                "languageId": selectedLenguage.languageId,
                "name": dataItem.name,
                "registerDescription": dataItem.registerDescription
            });

        else
            AdditiveService.updateLocalization({
                "additiveId": dataItem.additiveId,
                "languageId": selectedLenguage.languageId,
                "name": dataItem.name,
                "registerDescription": dataItem.registerDescription
            });

        setEditableAdditive(dataItem);

    }

    return (
        <div>
            <Form
                onSubmit={handleSubmit}
                initialValues={editableAdditive}
                key={JSON.stringify(editableAdditive)}
                render={(formRenderProps) => (
                    <FormElement>
                        <fieldset>
                            <div className="card">
                                <div className="card-header d-flex justify-content-between align-items-center">

                                    <b>{t("Additive_Details_GeneralData_Title")}</b>

                                    <DropDownList
                                        label={t("Global_Label_Text_Language")}
                                        name="language"
                                        loading={loading}
                                        textField="name"
                                        value={selectedLenguage}
                                        dataItemKey="languageId"
                                        style={{ width: '200px' }}
                                        onChange={e => setSelectedLenguage(e.value)}
                                        data={languages}
                                    />

                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-sm-3">
                                            <Field
                                                label={t("Additive_ID_Label")}
                                                name={'additiveId'}
                                                disabled={true}
                                                loading={loading}
                                                component={CustomInput}
                                                onChange={e => setAdditive({ ...additive, name: e.target.value })}
                                            />
                                        </div>
                                        <div className="col-sm-9">
                                            <Field
                                                label={t("Additive_Name_Label")}
                                                name={'name'}
                                                disabled={!isAdmin}
                                                loading={loading}
                                                component={CustomInput}
                                                //validator={[requiredValidator]}
                                                onChange={e => setAdditive({ ...additive, name: e.target.value })}
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <Field
                                                label={t("Additive_RegisterDescription_Label")}
                                                name={'registerDescription'}
                                                component={CustomTextArea}
                                                disabled={!isAdmin}
                                                maxLength={1000}
                                                loading={loading}
                                                //validator={[requiredValidator]}
                                                onChange={e => setAdditive({ ...additive, name: e.target.value })}
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-4">
                                            <Field
                                                label={t("Additive_RegisterFirstDate_Label")}
                                                name={'registerFirstEntryDate'}
                                                spinners={true}
                                                format="dd/MM/yyyy"
                                                loading={loading}
                                                disabled={!isAdmin}
                                                //formatPlaceholder="wide"
                                                component={CustomDateInput}
                                                //validator={[requiredValidator]}
                                                onChange={e => setAdditive({ ...additive, name: e.target.value })}
                                            />
                                        </div>
                                        <div className="col-sm-4">
                                            <Field
                                                label={t("Additive_AuthorizationDate_Label")}
                                                name={'authorisationDate'}
                                                component={CustomDateInput}
                                                spinners={true}
                                                disabled={!isAdmin}
                                                loading={loading}
                                                format="dd/MM/yyyy"
                                                //formatPlaceholder="wide"
                                                //validator={[requiredValidator]}
                                                onChange={e => setAdditive({ ...additive, name: e.target.value })}
                                            />
                                        </div>
                                        <div className="col-sm-4">
                                            <Field
                                                label={t("Additive_AuthorizationExpirationDate_Label")}
                                                name={'authorisationExpirationDate'}
                                                component={CustomDateInput}
                                                spinners={true}
                                                disabled={!isAdmin}
                                                loading={loading}
                                                format="dd/MM/yyyy"
                                                //formatPlaceholder="wide"
                                                //validator={[requiredValidator]}
                                                onChange={e => setAdditive({ ...additive, name: e.target.value })}
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <Field
                                                label={t("Additive_Remarks_Label")}
                                                name={'remarks'}
                                                component={CustomTextArea}
                                                disabled={!isAdmin}
                                                maxLength={1000}
                                                loading={loading}
                                                style={{ width: "100%" }}
                                                onChange={e => setAdditive({ ...additive, name: e.target.value })}
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-3">
                                            <Field
                                                label={t("Additive_HasAlternativeIdentifier_Label")}
                                                disabled={!isAdmin}
                                                name={'hasAlternativeIdentifier'}
                                                loading={loading}
                                                onChange={e => setAdditive({ ...additive, name: e.target.value })}
                                                component={CustomCheckbox} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </fieldset>
                        <br />
                        {isAdmin ? (<div className="float-sm-right k-form-buttons">
                            <button
                                type={'submit'}
                                disabled={!formRenderProps.allowSubmit}
                                className="k-button"
                            >
                                <span className="k-icon k-i-save" /> &nbsp; {t("Global_Button_Text_Save")}
                            </button>
                        </div>) : (< span />)}
                    </FormElement>)}
            />
        </div>
    );
}
