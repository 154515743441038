import * as React from 'react';
import { DropDownList } from '@progress/kendo-react-dropdowns';

export default function AnalyticComponentsDropDownCell(props) {

    const handleChange = (e) => {
        props.onChange({
            dataItem: props.dataItem,
            field: props.field,
            syntheticEvent: e.syntheticEvent,
            value: e.target.value.analyticComponentId
        });
    }
    const { dataItem, field } = props;

    return (
        <td>
            {dataItem.inEdit ? (
                <DropDownList
                    style={{ width: "200px" }}
                    onChange={handleChange}
                    value={props.analyticComponents.find(c => c.analyticComponentId === dataItem[field])}
                    data={props.analyticComponents}
                    textField="name"
                    dataItemKey="analyticComponentId"
                />
            ) : (
                    (props.analyticComponents.find(c => c.analyticComponentId === dataItem[field])).name
                )}
        </td>
    );
}
