import * as React from 'react';
import { Link } from 'react-router-dom'
import { useTranslation } from "react-i18next";
import { avaiableLangList } from '../../i18nextConf'
import { Grid, GridToolbar, GridColumn as Column } from '@progress/kendo-react-grid';
import AdditivesService from "../../services/Additives.service";
import { CustomListCommandCell } from "../../components/GridCustomListCommandCell";
import { GridCustomLoadingCell } from "../../components/GridCustomLoadingCell";
import { PanelCustomLoader } from "../../components/PanelCustomLoader";
import { FilterAdditivePanel } from '../../components/FilterAdditivePanel'



export default function List(props) {

    const { t } = useTranslation();
    const isAdmin = props.isAdmin;

    const CommandCell = props => (
        <CustomListCommandCell
            {...props}
            editField='inEdit'
            remove={remove}
            linkToDetails={'/Additives/' + props.dataItem.additiveId + '/Edit'}
            isAdmin={isAdmin}
        />
    );

    const [state, setState] = React.useState({ skip: 0, take: 10 });
    const [results, setResults] = React.useState(0);
    const [additives, setAdditives] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const [sorting, setSorting] = React.useState(null);
    const [sortingBy, setSortingBy] = React.useState(null);
    const [filter, setFilter] = React.useState('');


    React.useEffect(() => {

        setLoading(true);

        var selected = localStorage.getItem("i18nextLng") || "en";
        var selectedLangDetails = avaiableLangList.find(lang => lang.short === selected);

        var order = '';

        if (sortingBy)
            order = sortingBy;
        else
            order = 'additiveId';



        if (filter)
            AdditivesService.getAdditivesFiltered(Math.ceil((state.skip + 1) / 10), state.take, order, selectedLangDetails.id, filter)
                .then(response => response.data)
                .then(json => {
                    setAdditives(json.contract);
                    setResults(json.totalResults);
                    setLoading(false);
                });
        else
            AdditivesService.getAdditivesOrderBy(Math.ceil((state.skip + 1) / 10), state.take, order, selectedLangDetails.id)
                .then(response => response.data)
                .then(json => {
                    setAdditives(json.contract);
                    setResults(json.totalResults);
                    setLoading(false);
                });


    }, [state, sortingBy, filter]);


    const sortChange = (event) => {

        if (event.sort.length === 0) {
            if (sorting != null) {

                setSorting(null);
                setSortingBy(null);
            }
        }
        else {

            setSorting(event.sort);
            setSortingBy(event.sort[0].field + " " + event.sort[0].dir);
        }

    }

    const remove = (dataItem) => {

        AdditivesService.delete(dataItem).then(response => {

            if (response.status === 200)
                setState({ skip: state.skip, take: state.take });

        });

    }

    const pageChange = (event) => {
        setState({ skip: event.page.skip, take: event.page.take });
    }

    return (
        <div>
            {loading && PanelCustomLoader}

            <
                FilterAdditivePanel
                setFilter={setFilter}
            />


            <br />
            <Grid
                data={additives}
                editField='inEdit'
                skip={state.skip}
                take={state.take}
                total={results}
                pageable={{ buttonCount: 4, pageSizes: [10, 20, 50, 100] }}
                scrollable={'none'}
                onPageChange={pageChange}
                cellRender={GridCustomLoadingCell}
                sort={sorting}
                sortable={{
                    allowUnsort: true,
                    mode: 'single'
                }}
                onSortChange={sortChange}
            >
                {isAdmin ? (<GridToolbar>
                    <div>
                        <Link to={'/Additives/Add'}>
                            <button title={t("Additive_Button_Tooltip_New")} className="k-button k-primary">
                                &nbsp;<span className="k-icon k-i-plus-circle" />&nbsp; {t("Global_Button_Text_New")} &nbsp;
                        </button>
                        </Link>
                    </div>
                </GridToolbar>) : (<span />)}
                <Column cell={CommandCell} width={isAdmin ? ("160px") : ("80px")} sortable={false} />
                <Column field="additiveId" title={t("Additive_ID_Label")} />
                <Column field="name" title={t("Additive_Name_Label")} />
            </Grid>
        </div>
    );
}
