import AuthService from './auth.service';

export default function authHeader() {

    const user = AuthService.GetCookie();

    if (user) {
        return { Authorization: 'Bearer ' + user };
    } else {
        return {};
    }
}