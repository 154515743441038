import React from 'react';
import RawMaterialGroupEdit from '../../Models/RawMaterialGroups/Edit';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import RawMaterialsGroupsService from "../../services/RawMaterialsGroups.service";
import AuthService from "../../services/auth.service";

export default function RawMaterialGroupDetails(props) {

    const { params } = props.match;
    const { t } = useTranslation();

    const [rawMaterialGroup, setRawMaterialGroup] = React.useState({
        "rawMaterialGroup": 0,
        "name": ""
    });

    React.useEffect(() => {
        RawMaterialsGroupsService.getGroup(params.rawMaterialGroupId)
            .then(response => response.data.contract)
            .then(materialGroup => {
                setRawMaterialGroup({
                    "rawMaterialGroupId": params.rawMaterialGroupId,
                    "name": materialGroup.name
                });
            });
    }, [params.rawMaterialGroupId]);

    return (
        <div>
            <br />
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to={'/home/'}>{t("Home_Breadcrumb_Title")}</Link></li>
                    <li className="breadcrumb-item"><Link to={'/RawMaterialGroups'}>{t("RawMaterials_Group_Title")}</Link></li>
                    <li className="breadcrumb-item active">{params.rawMaterialGroupId}</li>
                </ol>
            </nav>
            <br />
            <div className="card text-center">
                <div className="card-header">
                    <ul className="nav nav-tabs card-header-tabs">
                        <li className="nav-item">
                            <Link to={'/RawMaterialGroups/' + params.rawMaterialGroupId + '/Edit/'} className="nav-link active">{t("RawMaterials_Details_Tab")}</Link>
                        </li>
                        <li className="nav-item">
                            <Link to={'/RawMaterialGroups/' + params.rawMaterialGroupId + '/Edit/Statements'} className="nav-link">{t("RawMaterials_Statements_Tab")}</Link>
                        </li>
                    </ul>
                </div>
                <div className="card-body">
                    <RawMaterialGroupEdit
                        materialData={rawMaterialGroup}
                        isAdmin={AuthService.isUSerAdmin()}
                    />
                </div>
            </div>
        </div >
    );

}
