import * as React from 'react';
import { CustomDatePickerInput, CustomDropDownOptions } from "./CustomComponents"
import { Input } from "@progress/kendo-react-inputs";
import { useTranslation } from "react-i18next";
import { Collapse } from 'bootstrap';
import { DropDownList } from "@progress/kendo-react-dropdowns";

function ProccessValueContains(filter, value, name) {
    if (value)
        if (filter)
            filter += 'and contains(' + name + ',\'' + value + '\')';
        else
            filter += 'contains(' + name + ',\'' + value + '\')';

    return filter;
}

function ProccessValueGreater(filter, value, name) {
    if (value)
        if (filter)
            filter += ' and ' + name + ' gt ' + value.toJSON() + ' ';
        else
            filter += ' ' + name + '  gt ' + value.toJSON() + ' ';

    return filter;
}

function ProccessValueLower(filter, value, name) {
    if (value) {
        if (filter)
            filter += ' and ';

        filter += ' ' + name + '  lt ' + value.toJSON() + ' ';
    }

    return filter;
}

function ReturnDateRankBySelectedOption(filter, option, name, valueFrom, valueTo) {



    var date;
    var firstDay;
    var lastDay;

    switch (option) {
        case 1: // lastDay

            date = new Date();
            firstDay = new Date(date.getFullYear(), date.getMonth(), date.getDay(), 0);
            lastDay = new Date(date.getFullYear(), date.getMonth(), date.getDay() - 1, 0);

            if (filter)
                filter += ' and ';

            filter += name + '  lt ' + firstDay.toJSON() + ' and ' + name + '  gt ' + lastDay.toJSON() + ' ';

            break;

        case 2: // last week


            firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
            lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

            if (filter)
                filter += ' and ' + name + '  lt ' + lastDay.toJSON() + ' and ' + name + '  gt ' + firstDay.toJSON() + ' ';
            else
                filter += name + '  lt ' + lastDay.toJSON() + ' and ' + name + '  gt ' + firstDay.toJSON() + ' ';

            break;

        case 3: // last month

            date = new Date();
            firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
            lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

            if (filter)
                filter += ' and ' + name + '  lt ' + lastDay.toJSON() + ' and ' + name + '  gt ' + firstDay.toJSON() + ' ';
            else
                filter += name + '  lt ' + lastDay.toJSON() + ' and ' + name + '  gt ' + firstDay.toJSON() + ' ';

            break;

        case 4: // Last year


            date = new Date();
            firstDay = new Date(date.getFullYear(), 0, 1);
            lastDay = new Date(date.getFullYear(), date.getMonth(), date.getDay());

            if (filter)
                filter += ' and ' + name + '  lt ' + lastDay.toJSON() + ' and ' + name + '  gt ' + firstDay.toJSON() + ' ';
            else
                filter += ' ' + name + '  lt ' + lastDay.toJSON() + ' and ' + name + '  gt ' + firstDay.toJSON() + ' ';

            break;

        case 5:
            date = new Date();
            firstDay = new Date(date.getFullYear() - 100, date.getMonth(), 1);

            if (filter)
                filter += ' and ' + name + ' gt ' + firstDay.toJSON() + ' ';
            else
                filter += name + ' gt ' + firstDay.toJSON();

            break;

        case 6:
            filter = ProccessValueGreater(filter, valueFrom, name);
            filter = ProccessValueLower(filter, valueTo, name);
            break;


        default:
            console.log('default');
    }

    return filter;
}



export const FilterAdditivePanel = ({ handlerFilterSearch, setFilter }) => {

    const { t } = useTranslation();

    const [state, setState] = React.useState({ additiveRegisterFirstDateFrom: null, additiveRegisterFirstDateTo: null, authorizationDateFrom: null, authorizationDateTo: null, authorizationExpirationDateTo: null, authorizationExpirationDateFrom: null });

    const searchDateModes = [
        { text: t("Global_Options_Text_LastDay"), id: 1 },
        { text: t("Global_Options_Text_LastWeek"), id: 2 },
        { text: t("Global_Options_Text_LastMonth"), id: 3 },
        { text: t("Global_Options_Text_LastYear"), id: 4 },
        { text: t("Global_Options_Text_SinceEver"), id: 5 },
        { text: t("Global_Options_Text_Custom"), id: 6 }
    ];

    const defaultItemDates = { text: t("Global_Options_Text_None"), id: 0 };

    var [valuesDropDowns, setValuesDropDowns] = React.useState({ authorizationDate: 0, registerFirstEntryDate: 0, authorisationExpirationDate: 0 });
    var [valuesInputs, setValuesInputs] = React.useState({ additiveId: "", additiveName: "" });

    var [toggle, setToggle] = React.useState(false);

    React.useEffect(() => {
        var myCollapse = document.getElementById('collapseTarget')
        var bsCollapse = new Collapse(myCollapse, { toggle: false })
        toggle ? bsCollapse.show() : bsCollapse.hide()
    })


    const handleFilter = (event) => {

        var filter = '';

        console.log(valuesDropDowns);

        if (valuesInputs.additiveId)
            filter = ProccessValueContains(filter, valuesInputs.additiveId, 'additiveId');

        if (valuesInputs.name)
            filter = ProccessValueContains(filter, valuesInputs.name, 'name');

        filter = ReturnDateRankBySelectedOption(filter, valuesDropDowns.registerFirstEntryDate, 'registerFirstEntryDate', state.additiveRegisterFirstDateFrom, state.additiveRegisterFirstDateTo);
        filter = ReturnDateRankBySelectedOption(filter, valuesDropDowns.authorizationDate, 'authorisationDate', state.authorizationDateFrom, state.authorizationDateTo);
        filter = ReturnDateRankBySelectedOption(filter, valuesDropDowns.authorisationExpirationDate, 'authorisationExpirationDate', state.authorisationExpirationDateFrom, state.authorisationExpirationDateTo);

        setFilter(filter);
    }

    const handleChangeAdditiveRegisterFirstDate = (event) => {

        var myCollapseFrom = document.getElementById('collapseAdditiveRegisterFirstDateFrom');
        var bsCollapseFrom = new Collapse(myCollapseFrom, { toggle: false });
        var myCollapseTo = document.getElementById('collapseAdditiveRegisterFirstDateTo');
        var bsCollapseTo = new Collapse(myCollapseTo, { toggle: false });

        if (event.target.value.id === 6) {
            bsCollapseFrom.show();
            bsCollapseTo.show();
        }
        else {
            bsCollapseFrom.hide();
            bsCollapseTo.hide();
        }

        setValuesDropDowns({ ...valuesDropDowns, registerFirstEntryDate: event.target.value.id });

    }

    const handleChangeAuthorizationDate = (event) => {

        var myCollapseFrom = document.getElementById('collapseAuthorizationDateFrom');
        var bsCollapseFrom = new Collapse(myCollapseFrom, { toggle: false });
        var myCollapseTo = document.getElementById('collapseAuthorizationDateTo');
        var bsCollapseTo = new Collapse(myCollapseTo, { toggle: false });

        if (event.target.value.id === 6) {
            bsCollapseFrom.show();
            bsCollapseTo.show();
        }
        else {
            bsCollapseFrom.hide();
            bsCollapseTo.hide();
        }

        setValuesDropDowns({ ...valuesDropDowns, authorizationDate: event.target.value.id });

    }

    const handleChangeAuthorizationExpirationDate = (event) => {

        var myCollapseFrom = document.getElementById('collapseAuthorizationExpirationDateFrom');
        var bsCollapseFrom = new Collapse(myCollapseFrom, { toggle: false });
        var myCollapseTo = document.getElementById('collapseAuthorizationExpirationDateTo');
        var bsCollapseTo = new Collapse(myCollapseTo, { toggle: false });

        if (event.target.value.id === 6) {
            bsCollapseFrom.show();
            bsCollapseTo.show();
        }
        else {
            bsCollapseFrom.hide();
            bsCollapseTo.hide();
        }

        setValuesDropDowns({ ...valuesDropDowns, authorisationExpirationDate: event.target.value.id });

    }


    return (

        <div className="card">
            <div className="card-body container">
                <div className="row">
                    <div className="col-sm-4 text-center justify-content-center align-self-center" >
                        <Input
                            name={'additiveId'}
                            style={{ width: '100%' }}
                            label={t("Additive_ID_Label")}
                            placeholder={t("Additive_Search_AdditiveIdContains_Placeholder")}
                            onChange={e => { setValuesInputs({ ...valuesInputs, additiveId: e.value }) }}
                        />
                    </div>
                    <div className="col-sm-4 text-center justify-content-center align-self-center" >
                        <Input
                            name={'additiveName'}
                            style={{ width: '100%' }}
                            label={t("Additive_Name_Label")}
                            placeholder={t("Additive_Search_NameContains_Placeholder")}
                            onChange={e => { setValuesInputs({ ...valuesInputs, name: e.value }) }}
                        />
                    </div>
                    <div className="col-sm-4 text-center justify-content-center align-self-center">

                        <br />
                        <button className="btn btn-primary" onClick={() => setToggle(toggle => !toggle)}>{t("Additive_Search_ByDate_Button")}</button>
                                                &nbsp;
                        <button className="k-button k-primary" onClick={handleFilter}> {t("RawMaterials_Search_Button_Label")} </button>
                    </div>
                </div>
                <div className="collapse" id="collapseTarget">
                    <div className="row">
                        <div className="col-sm-4 text-center justify-content-center align-self-center" >
                            <DropDownList
                                style={{ width: '100%' }}
                                data={searchDateModes}
                                name={'authorizationExpirationDateSelector'}
                                defaultItem={defaultItemDates}
                                label={t("Additive_RegisterFirstDate_Label_search")}
                                onChange={handleChangeAdditiveRegisterFirstDate}
                                textField="text"
                                dataItemKey="id"
                                component={CustomDropDownOptions}
                            />
                        </div>
                        <div className="col-sm-4 text-center justify-content-center align-self-center collapse" id="collapseAdditiveRegisterFirstDateFrom" >
                            <CustomDatePickerInput
                                name={'additiveRegisterFirstDateFrom'}
                                label={t("Additive_RegisterFirstDate_Label_from")}
                                onChange={e => { setState({ ...state, additiveRegisterFirstDateFrom: e.target.value }) }}
                                format="dd/MM/yyyy"
                            />
                        </div>
                        <div className="col-sm-4 text-center justify-content-center align-self-center collapse" id="collapseAdditiveRegisterFirstDateTo"  >
                            <CustomDatePickerInput
                                name={'additiveRegisterFirstDateTo'}
                                label={t("Additive_RegisterFirstDate_Label_to")}
                                onChange={e => { setState({ ...state, additiveRegisterFirstDateTo: e.target.value }) }}
                                format="dd/MM/yyyy"
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-sm-4 text-center justify-content-center align-self-center" >
                            <DropDownList
                                data={searchDateModes}
                                style={{ width: '100%' }}
                                name={'authorizationDateSelector'}
                                label={t("Additive_AuthorizationDate_Label_from")}
                                defaultItem={defaultItemDates}
                                onChange={handleChangeAuthorizationDate}
                                textField="text"
                                dataItemKey="id"
                                component={CustomDropDownOptions}
                            />
                        </div>
                        <div className="col-sm-4 text-center justify-content-center align-self-center collapse" id="collapseAuthorizationDateFrom" >
                            <CustomDatePickerInput
                                name={'authorizationDateFrom'}
                                label={t("Additive_AuthorizationDate_Label_from")}
                                onChange={e => { setState({ ...state, authorizationDateFrom: e.target.value }) }}
                                format="dd/MM/yyyy"
                            />
                        </div>
                        <div className="col-sm-4 text-center justify-content-center align-self-center collapse" id="collapseAuthorizationDateTo" >
                            <CustomDatePickerInput
                                name={'authorizationDateTo'}
                                label={t("Additive_AuthorizationDate_Label_to")}
                                onChange={e => { setState({ ...state, authorizationDateTo: e.target.value }) }}
                                format="dd/MM/yyyy"
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-sm-4 text-center justify-content-center align-self-center" >
                            <DropDownList
                                data={searchDateModes}
                                style={{ width: '100%' }}
                                name={'authorizationExpirationDateSelector'}
                                label={t("Additive_AuthorizationExpirationDate_Label_from")}
                                defaultItem={defaultItemDates}
                                onChange={handleChangeAuthorizationExpirationDate}
                                textField="text"
                                dataItemKey="id"
                            />
                        </div>
                        <div className="col-sm-4 text-center justify-content-center align-self-center collapse" id="collapseAuthorizationExpirationDateFrom" >
                            <CustomDatePickerInput
                                name={'authorizationExpirationDateFrom'}
                                label={t("Additive_AuthorizationExpirationDate_Label_from")}
                                onChange={e => { setState({ ...state, authorisationExpirationDateFrom: e.target.value }) }}
                                format="dd/MM/yyyy"
                            />
                        </div>
                        <div className="col-sm-4 text-center justify-content-center align-self-center collapse" id="collapseAuthorizationExpirationDateTo" >
                            <CustomDatePickerInput
                                name={'authorizationExpirationDateTo'}
                                label={t("Additive_AuthorizationExpirationDate_Label_to")}
                                onChange={e => { setState({ ...state, authorisationExpirationDateTo: e.target.value }) }}
                                format="dd/MM/yyyy"
                            />
                        </div>
                    </div>
                </div>
            </div>

        </div >

    );
};
