import * as React from 'react';
import { Link } from 'react-router-dom'
import { avaiableLangList } from '../../i18nextConf'
import { useTranslation } from "react-i18next";
import { Grid, GridToolbar, GridColumn as Column } from '@progress/kendo-react-grid';
import AdditiveCategoriesService from "../../services/AdditiveCategories.service";
import LanguagesService from "../../services/Languages.service";
import { CustomListCommandCell } from "../../components/GridCustomListCommandCell";
import { GridCustomLoadingCell } from "../../components/GridCustomLoadingCell";
import { PanelCustomLoader } from "../../components/PanelCustomLoader";

export default function List(props) {

    const { t } = useTranslation();
    const isAdmin = props.isAdmin;

    const CommandCell = props => (
        <CustomListCommandCell
            {...props}
            editField='inEdit'
            remove={remove}
            linkToDetails={'/AdditiveCategories/' + props.dataItem.additiveCategoryId + '/Edit'}
            isAdmin={isAdmin}
        />
    );

    const [results, setResults] = React.useState(0);
    const [additives, setAdditives] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const [selectedLenguage, setSelectedLenguage] = React.useState({ name: 'Loading...', languageId: "es-ES" });

    React.useEffect(() => {
        LanguagesService.getLanguages()
            .then(response => response.data.contract)
            .then(json => {

                var selected = localStorage.getItem("i18nextLng") || "en";
                var selectedLangDetails = avaiableLangList.find(lang => lang.short === selected);
                var defaultLang = json.find(lang => lang.languageId === selectedLangDetails.id);

                setSelectedLenguage(defaultLang);
            });

    }, [t]);

    React.useEffect(() => {

        setLoading(true);

        AdditiveCategoriesService.getAdditiveCategories(selectedLenguage.languageId)
            .then(response => response.data)
            .then(json => {
                setAdditives(json.contract);
                setResults(json.totalResults);
                setLoading(false);
            });
    }, [selectedLenguage.languageId]);


    const remove = (dataItem) => {

        AdditiveCategoriesService.delete(dataItem).then(response => {

            AdditiveCategoriesService.getAdditiveCategories()
                .then(response => response.data)
                .then(json => {
                    setAdditives(json.contract);
                    setResults(json.totalResults);
                    setLoading(false);
                });

        });

    }

    return (
        <div>
            {loading && PanelCustomLoader}

            <Grid
                data={additives}
                editField='inEdit'
                total={results}
                scrollable={'none'}
                cellRender={GridCustomLoadingCell}
            >
                {isAdmin ? (<GridToolbar>
                    <div>
                        <Link to={'/AdditiveCategories/Add'}>
                            <button title={t("AdditiveCategories_Button_Tooltip_New")} className="k-button k-primary">
                                &nbsp;<span className="k-icon k-i-plus-circle" />&nbsp; {t("Global_Button_Text_New")} &nbsp;
                        </button>
                        </Link>
                    </div>
                </GridToolbar>) : (<span />)}
                <Column cell={CommandCell} width={isAdmin ? ("160px") : ("80px")} sortable={false} />
                <Column field="additiveCategoryId" title={t("AdditiveCategories_ID_Label")} />
                <Column field="name" title={t("AdditiveCategories_Name_Label")} />
            </Grid>
        </div>
    );
}
