import React from 'react';
import RawMaterialGroupList from '../../Models/RawMaterialGroups/List';
import AuthService from "../../services/auth.service";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";


export default function RawMaterialGroup(props) {

    const { t } = useTranslation();

    return (
        <div>
            <br />
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to={'/home/'}>{t("Home_Breadcrumb_Title")}</Link></li>
                    <li className="breadcrumb-item active">{t("RawMaterials_Group_Title")}</li>
                </ol>
            </nav>
            <br />
            <RawMaterialGroupList
                isAdmin={AuthService.isUSerAdmin()}
            />
        </div >
    );
}
