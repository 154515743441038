import axios from 'axios';
import authHeader from './auth-header';
import AuthService from './auth.service'
const API_URL = 'https://flbasisapi.agrifoodatsolutions.com';
const axiosApiInstanceForRawMaterialGroups = axios.create();

axiosApiInstanceForRawMaterialGroups.interceptors.response.use(response => {
    return response;
}, async error => {
    const originalRequest = error.config;
    if (error.response.status === 401) {

        originalRequest._retry = true;
        await AuthService.relogin();
        originalRequest.headers = authHeader();
        return axiosApiInstanceForRawMaterialGroups(originalRequest);
    }

    if (error.response.status === 404) {
        console.log(error);
    }

    return Promise.reject(error);
});

class RawMaterialGroupsService {


    getGroups(languageId) {
        return axiosApiInstanceForRawMaterialGroups.get(API_URL + '/v1/rawMaterialGroups?languageId=' + languageId, { headers: authHeader() });
    }

    getGroup(id) {
        return axiosApiInstanceForRawMaterialGroups.get(API_URL + '/v1/rawMaterialGroups/' + id, { headers: authHeader() });
    }

    getLocalization(id, langId) {
        return axiosApiInstanceForRawMaterialGroups.get(API_URL + '/v1/rawMaterialGroups/' + id + '/localization/' + langId, { headers: authHeader() });
    }


    saveLocalization(RawMaterialGroup) {
        return axiosApiInstanceForRawMaterialGroups.post(API_URL + '/v1/rawMaterialGroups/' + RawMaterialGroup.rawMaterialGroupId + '/localization/', RawMaterialGroup, { headers: authHeader() });
    }

    updateLocalization(RawMaterialGroup, langId) {
        return axiosApiInstanceForRawMaterialGroups.put(API_URL + '/v1/rawMaterialGroups/' + RawMaterialGroup.rawMaterialGroupId + '/localization/' + langId, RawMaterialGroup, { headers: authHeader() });
    }


    update(RawMaterialGroup) {
        return axiosApiInstanceForRawMaterialGroups.put(API_URL + '/v1/rawMaterialGroups/' + RawMaterialGroup.rawMaterialGroupId, RawMaterialGroup, { headers: authHeader() });
    }

    delete(RawMaterialGroup) {
        return axiosApiInstanceForRawMaterialGroups.delete(API_URL + '/v1/rawMaterialGroups/' + RawMaterialGroup.rawMaterialGroupId, { headers: authHeader() });
    }

    getStatements(rawMaterialGroupId) {
        return axiosApiInstanceForRawMaterialGroups.get(API_URL + '/v1/rawMaterialGroups/' + rawMaterialGroupId + '/statements', { headers: authHeader() });
    }

    deleteStatments(rawMaterialGroupId, rawMaterialStatementId) {
        return axiosApiInstanceForRawMaterialGroups.delete(API_URL + '/v1/rawMaterialGroups/' + rawMaterialGroupId + '/statements/' + rawMaterialStatementId, { headers: authHeader() });
    }

    saveStatment(rawMaterialGroupId, rawMaterialGroupStatement) {
        return axiosApiInstanceForRawMaterialGroups.put(API_URL + '/v1/rawMaterialGroups/' + rawMaterialGroupId + '/statements', rawMaterialGroupStatement, { headers: authHeader() });
    }
    updateStatement(rawMaterialGroupId, rawMaterialStatementId, rawMaterialGroupStatement) {
        return axiosApiInstanceForRawMaterialGroups.put(API_URL + '/v1/rawMaterialGroups/' + rawMaterialGroupId + '/statements/' + rawMaterialStatementId, rawMaterialGroupStatement, { headers: authHeader() });
    }

}

export default new RawMaterialGroupsService();
