import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import * as React from 'react';
import { useTranslation } from "react-i18next";
import AdditivesDropDownCell from '../../components/DropDownListCustomAdditives';
import { PanelCustomLoader } from "../../components/PanelCustomLoader";
import AdditivesService from "../../services/Additives.service";
import FunctionalGroupsService from "../../services/FunctionalGroups.service";

export default function Additives(props) {

    const { t } = useTranslation();
    const editField = "inEdit";
    const isAdmin = props.isAdmin;

    const [functionalGroupsAdditives, setFunctionalGroupsAdditives] = React.useState([]);
    const [functionalGroup, setFunctionalGroup] = React.useState({ functionalGroupId: "", name: "" });
    const [additives, setAdditives] = React.useState([]);

    const [loading, setLoading] = React.useState(false);

    React.useEffect(() => {

        setLoading(true);
        console.log(props.materialData);
        FunctionalGroupsService.getFunctionalGroupAdditives(props.functionalGroupId)
            .then(response => response.data)
            .then(json => {
                setFunctionalGroupsAdditives(json.contract);
                setLoading(false);
            });

    }, [props.materialData, props.functionalGroupId]);


    React.useEffect(() => {

        AdditivesService.getAdditives(1, 1000, "es-ES")
            .then(response => response.data)
            .then(json => {
                setAdditives(json.contract);
            });

        FunctionalGroupsService.getGroup(props.functionalGroupId)
            .then(response => response.data.contract)
            .then(json => setFunctionalGroup(json));

    }, [props.functionalGroupId]);


    const MyCommandCell = (props) => {
        const { dataItem } = props;
        const inEdit = dataItem[props.editField];

        return inEdit ? (
            <td className="k-command-cell">
                <button
                    className="k-button k-grid-save-command"
                    title={t("Global_Button_Text_Add")}
                    onClick={() => (props.add(dataItem))}
                >
                    <span className="k-icon k-i-save" />
                </button>
            </td>
        ) : (
                <td className="k-command-cell">
                    <button
                        className="k-button k-grid-remove-command"
                        title={t("Global_Button_Text_Remove")}
                        onClick={() => {
                            props.remove(dataItem);
                        }}
                    >
                        <span className="k-icon k-i-close" />
                    </button>
                </td>
            );
    };

    const remove = dataItem => {
        dataItem.inEdit = false;
        setLoading(true);
        FunctionalGroupsService.deleteFunctionalGroupAdditive(props.functionalGroupId, dataItem.additive.additiveId)
            .then(response => {
                FunctionalGroupsService.getFunctionalGroupAdditives(props.functionalGroupId)
                    .then(response => response.data.contract)
                    .then(json => {
                        setFunctionalGroupsAdditives(json);
                        setLoading(false);
                    });
            })
    };

    const add = dataItem => {
        dataItem.inEdit = true;

        setLoading(true);
        console.log(dataItem);
        FunctionalGroupsService.saveFunctionalGroupAdditive(props.functionalGroupId, {
            "additiveId": dataItem.additiveId,
            "additiveCategoryId": functionalGroup.additiveCategoryId,
            "functionalGroupId": props.functionalGroupId
        })
            .then(response => {
                FunctionalGroupsService.getFunctionalGroupAdditives(props.functionalGroupId)
                    .then(response => response.data.contract)
                    .then(json => {
                        setFunctionalGroupsAdditives(json);
                        setLoading(false);
                    });
            });

    };

    const discard = dataItem => {
        setFunctionalGroupsAdditives(functionalGroupsAdditives.filter(item => item.additiveId !== dataItem.additiveId));
    };

    const cancel = dataItem => {
        setFunctionalGroupsAdditives(functionalGroupsAdditives.filter(functionalGroupsAdditives.map(item => (item.additiveId === dataItem.additiveId) ? { ...item, inEdit: false } : item)));
    };

    const enterEdit = dataItem => {
        setFunctionalGroupsAdditives(functionalGroupsAdditives.map(item => (item.additiveId === dataItem.additiveId) ? { ...item, inEdit: true } : item));
    };

    const itemChange = event => {
        const data = functionalGroupsAdditives.map(item => item.additiveId === event.dataItem.additiveId ? { ...item, [event.field]: event.value } : item);
        setFunctionalGroupsAdditives(data);
    };

    const CommandCell = props => (
        <MyCommandCell
            {...props}
            edit={enterEdit}
            remove={remove}
            add={add}
            discard={discard}
            cancel={cancel}
            editField={editField}
        />
    );


    const addNew = () => {

        const newDataItem = {
            isNew: true, inEdit: true, additive: { additiveId: "", name: "select additive" }
        };

        const data = [...functionalGroupsAdditives, newDataItem];

        setFunctionalGroupsAdditives(data);
    };

    const additiveDropDownCell = (props) => <AdditivesDropDownCell {...props} additives={additives} />

    return (
        <div>
            {loading && PanelCustomLoader}

            <div className="card">
                {isAdmin ? (<div className="card-header d-flex justify-content-between align-items-center">
                    <button
                        title={t("FunctionalGroups_Additives_Button_Tooltip_New")}
                        className="k-button k-primary"
                        onClick={addNew} >
                        &nbsp;<span className="k-icon k-i-plus-circle" />&nbsp; {t("Global_Button_Text_New")} &nbsp;
                            </button>
                </div>) : (<span />)}
                <div>

                    <Grid
                        itemChange={itemChange}
                        data={functionalGroupsAdditives}
                        editField={editField}
                        total={functionalGroupsAdditives.length}
                        pageable={false}
                        scrollable={'none'}
                        onItemChange={itemChange}
                    >
                        {isAdmin ? (<Column cell={CommandCell} width="70px" />) : (< span />)}
                        <Column field="additiveId" title={t("FunctionalGroups_Additives_Additive_Label")} cell={additiveDropDownCell} />
                    </Grid>
                </div>
            </div>
        </div>
    );

}
