import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import * as React from 'react';
import { useTranslation } from "react-i18next";
import { avaiableLangList } from '../../i18nextConf'
import FunctionalGroupDropDownCell from '../../components/DropDownListCustomFunctionalGroups';
import { PanelCustomLoader } from "../../components/PanelCustomLoader";
import AdditivesService from "../../services/Additives.service";
import FunctionalGroupsService from "../../services/FunctionalGroups.service";
import LanguagesService from "../../services/Languages.service";

export default function FunctionalGroup(props) {

    const { t } = useTranslation();
    const editField = "inEdit";
    const isAdmin = props.isAdmin;

    const [additiveFunctionalGroups, setAdditiveFunctionalGroups] = React.useState([]);
    const [additive, setAdditive] = React.useState({ additiveId: "", name: "" });
    const [functionalGroups, setFunctionalGroups] = React.useState([]);
    const [selectedLenguage, setSelectedLenguage] = React.useState({ name: 'Loading...', languageId: "es-ES" });

    const [loading, setLoading] = React.useState(false);

    React.useEffect(() => {
        LanguagesService.getLanguages()
            .then(response => response.data.contract)
            .then(json => {

                var selected = localStorage.getItem("i18nextLng") || "en";
                var selectedLangDetails = avaiableLangList.find(lang => lang.short === selected);
                var defaultLang = json.find(lang => lang.languageId === selectedLangDetails.id);

                setSelectedLenguage(defaultLang);
            });


    }, [t]);

    React.useEffect(() => {

        setLoading(true);
        FunctionalGroupsService.getFunctionalGroups(selectedLenguage.languageId)
            .then(response => response.data.contract)
            .then(json => {

                // name change to see order
                json.forEach(ad => ad.name = ad.additiveCategoryId + "." + ad.functionalGroupId + " - " + ad.name);

                setFunctionalGroups(json);
                AdditivesService.getAdditiveFunctionalGroups(props.additiveId)
                    .then(response => response.data)
                    .then(json => {
                        setAdditiveFunctionalGroups(json.contract);
                        setLoading(false);
                    });
            });


    }, [additive, props.additiveId, selectedLenguage.languageId]);


    React.useEffect(() => {

        if (props.additiveId)
            AdditivesService.get(props.additiveId)
                .then(response => response.data.contract)
                .then(json => setAdditive(json));

    }, [props.additiveId]);


    const MyCommandCell = (props) => {
        const { dataItem } = props;
        const inEdit = dataItem[props.editField];

        return inEdit ? (
            <td className="k-command-cell">
                <button
                    className="k-button k-grid-save-command"
                    title={t("Global_Button_Text_Add")}
                    onClick={() => (props.add(dataItem))}
                >
                    <span className="k-icon k-i-save" />
                </button>
            </td>
        ) : (
                <td className="k-command-cell">
                    <button
                        className="k-button k-grid-remove-command"
                        title={t("Global_Button_Text_Remove")}
                        onClick={() => {
                            props.remove(dataItem);
                        }}
                    >
                        <span className="k-icon k-i-close" />
                    </button>
                </td>
            );
    };

    const remove = dataItem => {
        dataItem.inEdit = false;
        setLoading(true);
        AdditivesService.deleteAdditiveFunctionalGroup(props.additiveId, dataItem.functionalGroupId)
            .then(response => {
                AdditivesService.getAdditiveFunctionalGroups(props.additiveId)
                    .then(response => response.data.contract)
                    .then(json => {
                        setAdditiveFunctionalGroups(json);
                        setLoading(false);
                    });
            })
    };

    const add = dataItem => {
        dataItem.inEdit = true;

        setLoading(true);
        console.log(dataItem);
        AdditivesService.saveAdditiveFunctionalGroup(props.additiveId, {
            "additiveId": props.additiveId,
            "additiveCategoryId": dataItem.functionalGroup.additiveCategoryId,
            "functionalGroupId": dataItem.functionalGroup.functionalGroupId
        })
            .then(response => {
                AdditivesService.getAdditiveFunctionalGroups(props.additiveId)
                    .then(response => response.data)
                    .then(json => {
                        setAdditiveFunctionalGroups(json.contract);
                        setLoading(false);
                    });
            });

    };

    const discard = dataItem => {
        setAdditiveFunctionalGroups(additiveFunctionalGroups.filter(item => item.functionalGroupId !== dataItem.functionalGroupId));
    };

    const cancel = dataItem => {
        setAdditiveFunctionalGroups(additiveFunctionalGroups.filter(additiveFunctionalGroups.map(item => (item.functionalGroupId === dataItem.functionalGroupId) ? { ...item, inEdit: false } : item)));
    };

    const enterEdit = dataItem => {
        setAdditiveFunctionalGroups(additiveFunctionalGroups.map(item => (item.functionalGroupId === dataItem.functionalGroupId) ? { ...item, inEdit: true } : item));
    };

    const itemChange = event => {
        const data = additiveFunctionalGroups.map(item => item.functionalGroupId === event.dataItem.functionalGroupId ? { ...item, [event.field]: event.value } : item);
        setAdditiveFunctionalGroups(data);
    };

    const CommandCell = props => (
        <MyCommandCell
            {...props}
            edit={enterEdit}
            remove={remove}
            add={add}
            discard={discard}
            cancel={cancel}
            editField={editField}
        />
    );


    const addNew = () => {

        const newDataItem = {
            isNew: true, inEdit: true, additive: { functionalGroupId: "", name: "select functionalGroup" }
        };

        const data = [...additiveFunctionalGroups, newDataItem];

        setAdditiveFunctionalGroups(data);
    };

    const functionalGroupDropDownCell = (props) => <FunctionalGroupDropDownCell {...props} functionalGroups={functionalGroups} />

    return (
        <div>
            {loading && PanelCustomLoader}

            <div className="card">
                {isAdmin ? (<div className="card-header d-flex justify-content-between align-items-center">
                    <button
                        title={t("Additive_FunctionalGroups_Button_Tooltip_New")}
                        className="k-button k-primary"
                        onClick={addNew} >
                        &nbsp;<span className="k-icon k-i-plus-circle" />&nbsp; {t("Global_Button_Text_New")} &nbsp;
                            </button>
                </div>) : (<span />)}
                <div>

                    <Grid
                        itemChange={itemChange}
                        data={additiveFunctionalGroups}
                        editField={editField}
                        total={additiveFunctionalGroups.length}
                        pageable={false}
                        scrollable={'none'}
                        onItemChange={itemChange}
                    >
                        {isAdmin ? (<Column cell={CommandCell} width="70px" />) : (< span />)}
                        <Column field="functionalGroup" title={t("Additive_FunctionalGroups_FunctionalGroup_Label")} cell={functionalGroupDropDownCell} />
                    </Grid>
                </div>
            </div>
        </div>
    );

}
