import axios from 'axios';
import authHeader from './auth-header';
import AuthService from './auth.service'
const API_URL = 'https://flbasisapi.agrifoodatsolutions.com';
const axiosApiInstanceForAdditiveCategories = axios.create();

axiosApiInstanceForAdditiveCategories.interceptors.response.use(response => {
    return response;
}, async error => {
    const originalRequest = error.config;
    if (error.response.status === 401) {

        originalRequest._retry = true;
        await AuthService.relogin();
        originalRequest.headers = authHeader();
        return axiosApiInstanceForAdditiveCategories(originalRequest);
    }

    if (error.response.status === 404) {
        console.log(error);
    }

    return Promise.reject(error);
});

class AdditiveCategoriesService {


    getAdditiveCategories(languageId) {
        return axiosApiInstanceForAdditiveCategories.get(API_URL + '/v1/additiveCategories/?languageId=' + languageId, { headers: authHeader() });
    }

    getAdditiveCategoryWithoutLangauge(id) {
        return axiosApiInstanceForAdditiveCategories.get(API_URL + '/v1/additiveCategories/' + id, { headers: authHeader() });
    }

    getAdditiveCategory(id, langId) {
        return axiosApiInstanceForAdditiveCategories.get(API_URL + '/v1/additiveCategories/' + id + '/localizations/' + langId, { headers: authHeader() });
    }

    create(AdditiveCategory) {
        return axiosApiInstanceForAdditiveCategories.post(API_URL + '/v1/additiveCategories/' + AdditiveCategory.additiveCategoryId, AdditiveCategory, { headers: authHeader() });
    }

    createLocalization(AdditiveCategoryLocalization) {
        return axiosApiInstanceForAdditiveCategories.post(API_URL + '/v1/additiveCategories/' + AdditiveCategoryLocalization.additiveCategoryId + '/localizations', AdditiveCategoryLocalization, { headers: authHeader() });
    }

    updateLocalization(AdditiveCategoryLocalization) {
        return axiosApiInstanceForAdditiveCategories.put(API_URL + '/v1/additiveCategories/' + AdditiveCategoryLocalization.additiveCategoryId + '/localizations/' + AdditiveCategoryLocalization.languageId, AdditiveCategoryLocalization, { headers: authHeader() });
    }

    update(AdditiveCategory) {
        return axiosApiInstanceForAdditiveCategories.put(API_URL + '/v1/additiveCategories/' + AdditiveCategory.additiveCategoryId, AdditiveCategory, { headers: authHeader() });
    }

    delete(AdditiveCategory) {
        return axiosApiInstanceForAdditiveCategories.delete(API_URL + '/v1/additiveCategories/' + AdditiveCategory.additiveCategoryId, { headers: authHeader() });
    }

}

export default new AdditiveCategoriesService();
