import * as React from 'react';
import { Link } from 'react-router-dom'

export const CustomListCommandCell = props => {
    const { dataItem } = props;

    return (<td className="k-command-cell">
        <Link to={props.linkToDetails}>
            <button className="k-button k-button-icontext">
                &nbsp;&nbsp;<span className=" k-icon k-i-pencil" title="Details"></span>&nbsp;
            </button>
        </Link>
        {props.isAdmin ? (
            <button className="k-button k-grid-remove-command" onClick={() => props.remove(dataItem)} >
                &nbsp;&nbsp;<span className=" k-icon k-i-delete" title="Delete"></span>&nbsp;&nbsp;
            </button>
        ) : (<span />)}

    </td>
    );
};
