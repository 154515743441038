import * as React from 'react';
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { CustomCheckbox, CustomInput, CustomDateInput, CustomTextArea } from "../../components/CustomComponents"
import AdditivesService from "../../services/Additives.service";
import LanguagesService from "../../services/Languages.service";
import { useTranslation } from "react-i18next";
import { avaiableLangList } from '../../i18nextConf'
import { DropDownList } from "@progress/kendo-react-dropdowns";

export default function AdditiveInsert(props) {

    const { t } = useTranslation();

    const [languages, setLanguages] = React.useState([]);
    const [selectedLenguage, setSelectedLenguage] = React.useState({ name: 'Loading...', languageId: "es-ES" });

    React.useEffect(() => {
        LanguagesService.getLanguages()
            .then(response => response.data.contract)
            .then(json => {
                setLanguages(json);

                var selected = localStorage.getItem("i18nextLng") || "en";
                var selectedLangDetails = avaiableLangList.find(lang => lang.short === selected);
                var defaultLang = json.find(lang => lang.languageId === selectedLangDetails.id);

                setSelectedLenguage(defaultLang);
            });



    }, []);

    const [additive, setAdditive] = React.useState({
        "additiveId": "",
        "name": "",
        "registerDescription": "",
        "authorisationDate": null,
        "authorisationExpirationDate": null,
        "registerFirstEntryDate": null,
        "remarks": "",
        "hasAlternativeIdentifier": false
    });

    const handleSubmit = (dataItem) => {

        AdditivesService.save({
            "additiveId": dataItem.additiveId,
            "name": dataItem.name,
            "registerDescription": dataItem.registerDescription,
            "authorisationDate": dataItem.authorisationDate.toJSON(),
            "authorisationExpirationDate": dataItem.authorisationExpirationDate.toJSON(),
            "registerFirstEntryDate": dataItem.registerFirstEntryDate.toJSON(),
            "remarks": dataItem.remarks || "",
            "hasAlternativeIdentifier": dataItem.hasAlternativeIdentifier
        }).then(response => response.data.contract)
            .then(
                json => {
                    AdditivesService.saveLocalization({
                        "additiveId": json.additiveId,
                        "name": dataItem.name,
                        "languageId": selectedLenguage.languageId,
                        "registerDescription": dataItem.registerDescription,
                        "remarks": dataItem.remarks || "",
                    }).then(response => response.data.contract)
                        .then(
                            end => {
                                return window.location.href = "/Additives/" + end.additiveId + "/Edit";
                            }
                        );
                }
            );

    }

    return (
        <div>
            <Form
                onSubmit={handleSubmit}
                initialValues={additive}
                key={JSON.stringify(additive)}
                render={(formRenderProps) => (
                    <FormElement>
                        <fieldset>
                            <div className="card">
                                <div className="card-header d-flex justify-content-between align-items-center">

                                    <b>{t("Additive_Details_GeneralData_Title")}</b>

                                    <DropDownList
                                        label={t("Global_Label_Text_Language")}
                                        name="language"
                                        textField="name"
                                        value={selectedLenguage}
                                        dataItemKey="languageId"
                                        style={{ width: '200px' }}
                                        onChange={e => setSelectedLenguage(e.value)}
                                        data={languages}
                                    />

                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-sm-3">
                                            <Field
                                                label={t("Additive_ID_Label")}
                                                name={'additiveId'}
                                                component={CustomInput}
                                                onChange={e => setAdditive({ ...additive, name: e.target.value })}
                                            />
                                        </div>
                                        <div className="col-sm-9">
                                            <Field
                                                label={t("Additive_Name_Label")}
                                                name={'name'}
                                                component={CustomInput}
                                                //validator={[requiredValidator]}
                                                onChange={e => setAdditive({ ...additive, name: e.target.value })}
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <Field
                                                label={t("Additive_RegisterDescription_Label")}
                                                name={'registerDescription'}
                                                component={CustomTextArea}
                                                maxLength={1000}
                                                //validator={[requiredValidator]}
                                                onChange={e => setAdditive({ ...additive, name: e.target.value })}
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-4">
                                            <Field
                                                label={t("Additive_RegisterFirstDate_Label")}
                                                name={'registerFirstEntryDate'}
                                                spinners={true}
                                                format="dd/MM/yyyy"
                                                //formatPlaceholder="wide"
                                                component={CustomDateInput}
                                                //validator={[requiredValidator]}
                                                onChange={e => setAdditive({ ...additive, name: e.target.value })}
                                            />
                                        </div>
                                        <div className="col-sm-4">
                                            <Field
                                                label={t("Additive_AuthorizationDate_Label")}
                                                name={'authorisationDate'}
                                                component={CustomDateInput}
                                                spinners={true}
                                                format="dd/MM/yyyy"
                                                //formatPlaceholder="wide"
                                                //validator={[requiredValidator]}
                                                onChange={e => setAdditive({ ...additive, name: e.target.value })}
                                            />
                                        </div>
                                        <div className="col-sm-4">
                                            <Field
                                                label={t("Additive_AuthorizationExpirationDate_Label")}
                                                name={'authorisationExpirationDate'}
                                                component={CustomDateInput}
                                                spinners={true}
                                                format="dd/MM/yyyy"
                                                //formatPlaceholder="wide"
                                                //validator={[requiredValidator]}
                                                onChange={e => setAdditive({ ...additive, name: e.target.value })}
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <Field
                                                label={t("Additive_Remarks_Label")}
                                                name={'remarks'}
                                                component={CustomTextArea}
                                                maxLength={1000}
                                                style={{ width: "100%" }}
                                                onChange={e => setAdditive({ ...additive, name: e.target.value })}
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-3">
                                            <Field
                                                label={t("Additive_HasAlternativeIdentifier_Label")}
                                                name={'hasAlternativeIdentifier'}
                                                onChange={e => setAdditive({ ...additive, name: e.target.value })}
                                                component={CustomCheckbox} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </fieldset>
                        <br />
                        <div className="float-sm-right k-form-buttons">
                            <button
                                type={'submit'}
                                disabled={!formRenderProps.allowSubmit}
                                className="k-button"
                            >
                                <span className="k-icon k-i-save" /> &nbsp; {t("Global_Button_Text_Save")}
                            </button>
                        </div>
                    </FormElement>)}
            />
        </div>
    );
}
