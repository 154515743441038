import * as React from 'react';
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import FunctionalGroupsService from "../../services/FunctionalGroups.service";
import AdditiveCategoriesService from "../../services/AdditiveCategories.service";
import { CustomInput, CustomDropDown, CustomCheckbox } from "../../components/CustomComponents"
import { useTranslation } from "react-i18next";
import LanguagesService from "../../services/Languages.service";
import { avaiableLangList } from '../../i18nextConf'
import { DropDownList } from "@progress/kendo-react-dropdowns";

export default function Edit(props) {

    const { t } = useTranslation();
    const isAdmin = props.isAdmin;

    const [loading, setLoading] = React.useState(true);
    const [languages, setLanguages] = React.useState([]);
    const [newResource, setNewResource] = React.useState(false);
    const [selectedLenguage, setSelectedLenguage] = React.useState({ name: "Loading...", languageId: "" });
    const [additiveCategories, setAdditiveCategories] = React.useState([]);

    const [functionalGroup, setFunctionalGroup] = React.useState({
        "functionalGroupId": "",
        "hasAlternativeIdentifier": false,
        "additiveCategory": { "additiveCategoryId": 0, "name": "" },
        "name": ""
    });


    React.useEffect(() => {

        LanguagesService.getLanguages()
            .then(response => response.data.contract)
            .then(json => {
                setLanguages(json);
                var selected = localStorage.getItem("i18nextLng") || "en";
                var selectedLangDetails = avaiableLangList.find(lang => lang.short === selected);
                var defaultLang = json.find(lang => lang.languageId === selectedLangDetails.id);
                setSelectedLenguage(defaultLang);
            });

    }, [t]);


    React.useEffect(() => {

        setLoading(true);
        AdditiveCategoriesService.getAdditiveCategories(selectedLenguage.languageId)
            .then(response => response.data.contract)
            .then(contract => {
                if (contract.length > 0)
                    contract.forEach(x => x.name = x.additiveCategoryId + " - " + x.name);
                setAdditiveCategories(contract);
                setLoading(false);
            });

    }, [props.functionalGroupId, selectedLenguage.languageId]);

    React.useEffect(() => {
        FunctionalGroupsService.getGroup(props.functionalGroupId)
            .then(response => response.data.contract)
            .then(fg => {
                FunctionalGroupsService.getGroupLocalization(props.functionalGroupId, selectedLenguage.languageId)
                    .then(response => response.data.contract)
                    .then(fgl => {
                        setFunctionalGroup({
                            "functionalGroupId": fg.functionalGroupId,
                            "hasAlternativeIdentifier": fg.hasAlternativeIdentifier,
                            "additiveCategory": (additiveCategories.find(category => category.additiveCategoryId === fg.additiveCategoryId)),
                            "name": fgl.name
                        });
                        setLoading(false);
                    }).catch(err => {
                        setNewResource(true);
                        setFunctionalGroup({
                            "functionalGroupId": fg.functionalGroupId,
                            "hasAlternativeIdentifier": fg.hasAlternativeIdentifier,
                            "additiveCategory": (additiveCategories.find(category => category.additiveCategoryId === fg.additiveCategoryId)),
                            "name": ""
                        });
                        setLoading(false);
                    });
            });
    }, [props.functionalGroupId, selectedLenguage, additiveCategories]);

    const handleSubmit = (dataItem) => {

        FunctionalGroupsService.update({
            "additiveCategoryId": dataItem.additiveCategory.additiveCategoryId,
            "functionalGroupId": dataItem.functionalGroupId,
            "hasAlternativeIdentifier": dataItem.hasAlternativeIdentifier,
        });

        if (newResource) {

            FunctionalGroupsService.insertLocalization({
                "additiveCategoryId": dataItem.additiveCategory.additiveCategoryId,
                "functionalGroupId": dataItem.functionalGroupId,
                "languageId": selectedLenguage.languageId,
                "name": dataItem.name
            });

        }
        else {

            FunctionalGroupsService.updateLocalization({
                "additiveCategoryId": dataItem.additiveCategory.additiveCategoryId,
                "functionalGroupId": dataItem.functionalGroupId,
                "languageId": selectedLenguage.languageId,
                "name": dataItem.name
            });


        }



        setFunctionalGroup(dataItem);

    }

    return (
        <div>
            <Form
                onSubmit={handleSubmit}
                initialValues={functionalGroup}
                key={JSON.stringify(functionalGroup)}
                render={(formRenderProps) => (
                    <FormElement>
                        <fieldset>
                            <div className="card">
                                <div className="card-header d-flex justify-content-between align-items-center">

                                    <b>{t("FunctionalGroups_Details_GeneralData_Title")}</b>

                                    <DropDownList
                                        label={t("Global_Label_Text_Language")}
                                        name="language"
                                        loading={loading}
                                        textField="name"
                                        value={selectedLenguage}
                                        dataItemKey="languageId"
                                        style={{ width: '200px' }}
                                        onChange={e => setSelectedLenguage(e.value)}
                                        data={languages}
                                    />

                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-sm-3">
                                            <Field
                                                label={t("FunctionalGroups_ID_Label")}
                                                name={'functionalGroupId'}
                                                disabled={true}
                                                loading={loading}
                                                component={CustomInput}
                                                onChange={e => setFunctionalGroup({ ...functionalGroup, name: e.target.value })}
                                            />
                                        </div>
                                        <div className="col-sm-9">
                                            <Field
                                                label={t("FunctionalGroups_Name_Label")}
                                                name={'name'}
                                                disabled={!isAdmin}
                                                loading={loading}
                                                component={CustomInput}
                                                //validator={[requiredValidator]}
                                                onChange={e => setFunctionalGroup({ ...functionalGroup, name: e.target.value })}
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <Field
                                                label={t("FunctionalGroup_AdditiveCategory_Label")}
                                                loading={loading}
                                                disabled={true}
                                                name="additiveCategory"
                                                textField="name"
                                                value={functionalGroup.additiveCategoryId}
                                                component={CustomDropDown}
                                                dataItemKey="additiveCategoryId"
                                                style={{ width: "100%" }}
                                                onChange={e => { setFunctionalGroup({ ...functionalGroup, name: e.target.value }) }}
                                                options={additiveCategories}
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-3">
                                            <Field
                                                label={t("FunctionalGroup_HasAlternativeIdentifier_Label")}
                                                disabled={!isAdmin}
                                                name={'hasAlternativeIdentifier'}
                                                loading={loading}
                                                onChange={e => setFunctionalGroup({ ...functionalGroup, name: e.target.value })}
                                                component={CustomCheckbox} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </fieldset>
                        <br />
                        {isAdmin ? (<div className="float-sm-right k-form-buttons">
                            <button
                                type={'submit'}
                                disabled={!formRenderProps.allowSubmit}
                                className="k-button"
                            >
                                <span className="k-icon k-i-save" /> &nbsp; {t("Global_Button_Text_Save")}
                            </button>
                        </div>) : (< span />)}
                    </FormElement>)}
            />
        </div>
    );
}
