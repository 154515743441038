import * as React from 'react';
import { Checkbox } from '@progress/kendo-react-inputs';

export default function checkboxCellGrid(props) {

    const { dataItem, field } = props;
    const dataValue = dataItem[field] === null ? '' : dataItem[field];

    return (
        <td>
            <Checkbox value={dataValue} disabled={!dataItem.inEdit} />
        </td>
    );
}
