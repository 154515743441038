import React from 'react';
import List from '../../Models/FunctionalGroups/List';
import AuthService from "../../services/auth.service";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function ListF(props) {

    const { t } = useTranslation();

    return (
        <div>
            <br />
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to={'/home/'}>{t("Home_Breadcrumb_Title")}</Link></li>
                    <li className="breadcrumb-item active">{t("FunctionalGroups_Breadcrumb_Title")}</li>
                </ol>
            </nav>
            <br />
            <List
                isAdmin={AuthService.isUSerAdmin()}
            />
        </div >
    );
}
