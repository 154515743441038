import React from 'react';
import Edit from '../../Models/FunctionalGroups/Additives';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import AuthService from "../../services/auth.service";

export default function Additives(props) {

    const { params } = props.match;
    const { t } = useTranslation();

    return (
        <div>
            <br />
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to={'/home/'}>{t("Home_Breadcrumb_Title")}</Link></li>
                    <li className="breadcrumb-item"><Link to={'/FunctionalGroups'}>{t("FunctionalGroups_Breadcrumb_Title")}</Link></li>
                    <li className="breadcrumb-item active">{params.functionalGroupId}</li>
                </ol>
            </nav>
            <div className="card text-center">
                <div className="card-header">
                    <ul className="nav nav-tabs card-header-tabs">
                        <li className="nav-item">
                            <Link to={'/FunctionalGroups/' + params.functionalGroupId + '/Edit/'} className="nav-link">{t("FunctionalGroups_Details_Tab")}</Link>
                        </li>
                        <li className="nav-item">
                            <Link to={'/FunctionalGroups/' + params.functionalGroupId + '/Edit/Additives'} className="nav-link active">{t("FunctionalGroups_Additives_Tab")}</Link>
                        </li>
                    </ul>
                </div>
                <div className="card-body">
                    <Edit
                        functionalGroupId={params.functionalGroupId}
                        isAdmin={AuthService.isUSerAdmin()}
                    />
                </div>
            </div>
            <br />
        </div >
    );


}
