import * as React from 'react';
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { CustomInput } from "../../components/CustomComponents"
import AdditiveCategoriesService from "../../services/AdditiveCategories.service";
import LanguagesService from "../../services/Languages.service";
import { useTranslation } from "react-i18next";
import { avaiableLangList } from '../../i18nextConf'
import { DropDownList } from "@progress/kendo-react-dropdowns";

export default function AdditiveInsert(props) {

    const { t } = useTranslation();

    const [languages, setLanguages] = React.useState([]);
    const [selectedLenguage, setSelectedLenguage] = React.useState({ name: 'Loading...', languageId: "es-ES" });

    React.useEffect(() => {
        LanguagesService.getLanguages()
            .then(response => response.data.contract)
            .then(json => {
                setLanguages(json);

                var selected = localStorage.getItem("i18nextLng") || "en";
                var selectedLangDetails = avaiableLangList.find(lang => lang.short === selected);
                var defaultLang = json.find(lang => lang.languageId === selectedLangDetails.id);

                setSelectedLenguage(defaultLang);
            });



    }, []);

    const [additiveCategory, setAdditiveCategory] = React.useState({
        "additiveCategoryId": "",
        "name": "",
    });

    const handleSubmit = (dataItem) => {

        AdditiveCategoriesService.save({
            "additiveCategoryId": dataItem.additiveCategoryId,
            "name": dataItem.name
        }).then(end => {
            return window.location.href = "/AdditiveCategories/" + end.additiveCategoryId + "/Edit";
        }
        );
    }


    return (
        <div>
            <Form
                onSubmit={handleSubmit}
                initialValues={additiveCategory}
                key={JSON.stringify(additiveCategory)}
                render={(formRenderProps) => (
                    <FormElement>
                        <fieldset>
                            <div className="card">
                                <div className="card-header d-flex justify-content-between align-items-center">

                                    <b>{t("AdditiveCategories_Details_GeneralData_Title")}</b>

                                    <DropDownList
                                        label={t("Global_Label_Text_Language")}
                                        name="language"
                                        textField="name"
                                        value={selectedLenguage}
                                        dataItemKey="languageId"
                                        style={{ width: '200px' }}
                                        onChange={e => setSelectedLenguage(e.value)}
                                        data={languages}
                                    />

                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-sm-3">
                                            <Field
                                                label={t("AdditiveCategories_ID_Label")}
                                                name={'additiveCategoryId'}
                                                component={CustomInput}
                                                onChange={e => setAdditiveCategory({ ...additiveCategory, additiveCategoryId: e.target.value })}
                                            />
                                        </div>
                                        <div className="col-sm-9">
                                            <Field
                                                label={t("AdditiveCategories_Name_Label")}
                                                name={'name'}
                                                component={CustomInput}
                                                //validator={[requiredValidator]}
                                                onChange={e => setAdditiveCategory({ ...additiveCategory, name: e.target.value })}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </fieldset>
                        <br />
                        <div className="float-sm-right k-form-buttons">
                            <button
                                type={'submit'}
                                disabled={!formRenderProps.allowSubmit}
                                className="k-button"
                            >
                                <span className="k-icon k-i-save" /> &nbsp; {t("Global_Button_Text_Save")}
                            </button>
                        </div>
                    </FormElement>)}
            />
        </div>
    );
}
