import * as React from 'react';
import { useTranslation } from "react-i18next";
import { avaiableLangList } from '../../i18nextConf'
import { Grid, GridToolbar, GridColumn as Column } from '@progress/kendo-react-grid';
import RawMaterialGroupService from "../../services/RawMaterialsGroups.service";
import LanguagesService from "../../services/Languages.service";
import { GridCustomLoadingCell } from "../../components/GridCustomLoadingCell";
import { PanelCustomLoader } from "../../components/PanelCustomLoader";
import { CustomListCommandCell } from "../../components/GridCustomListCommandCell";
import { Link } from "react-router-dom";


export default function RawMaterialGroupList(props) {

    const { t } = useTranslation();
    const isAdmin = props.isAdmin;

    const [state, setState] = React.useState({ skip: 0, take: 10, reload: false });
    const [results, setResults] = React.useState(0);
    const [rawMaterialGroups, setRawMaterialGroups] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const [selectedLenguage, setSelectedLenguage] = React.useState({ name: 'Loading...', languageId: "es-ES" });

    React.useEffect(() => {
        LanguagesService.getLanguages()
            .then(response => response.data.contract)
            .then(json => {

                var selected = localStorage.getItem("i18nextLng") || "en";
                var selectedLangDetails = avaiableLangList.find(lang => lang.short === selected);
                var defaultLang = json.find(lang => lang.languageId === selectedLangDetails.id);

                setSelectedLenguage(defaultLang);
            });



    }, [t]);

    React.useEffect(() => {

        setLoading(true);

        RawMaterialGroupService.getGroups(selectedLenguage.languageId)
            .then(response => response.data)
            .then(json => {
                setRawMaterialGroups(json.contract);
                setResults(json.contract.length);
                setLoading(false);
            });

    }, [state, selectedLenguage]);

    const remove = (dataItem) => {
        RawMaterialGroupService.delete(dataItem).then(response => {
            if (response.status === 200)
                setState({ skip: state.skip, take: state.take });
        });
    }


    const CommandCell = props => (
        <CustomListCommandCell
            {...props}
            editField='inEdit'
            remove={remove}
            linkToDetails={'/RawMaterialGroups/' + props.dataItem.rawMaterialGroupId + '/Edit'}
            isAdmin={isAdmin}
        />
    );

    return (
        <div>
            {loading && PanelCustomLoader}

            <Grid
                data={rawMaterialGroups}
                editField='inEdit'
                total={results}
                scrollable={'none'}
                cellRender={GridCustomLoadingCell}
            >
                {isAdmin ? (<GridToolbar>
                    <div>
                        <Link to={'/RawMaterialGroups/Add'}>
                            <button title={t("Additive_Button_Tooltip_New")} className="k-button k-primary">
                                &nbsp;<span className="k-icon k-i-plus-circle" />&nbsp; {t("Global_Button_Text_New")} &nbsp;
                        </button>
                        </Link>
                    </div>
                </GridToolbar>) : (<span />)}
                <Column cell={CommandCell} width={isAdmin ? ("160px") : ("80px")} sortable={false} />
                <Column field="rawMaterialGroupId" title={t("RawMaterialGroups_ID_Label")} />
                <Column field="name" title={t("RawMaterialGroups_Name_Label")} />

            </Grid>
        </div>
    );
}
