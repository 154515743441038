import * as React from 'react';

export const GridCustomLoadingCell = (tdElement, props) => {
    if (props.dataItem[props.field] === undefined) {
        // shows loading cell if no data
        return <td> <span className="k-placeholder-line"></span></td>;
    }

    // default rendering for this cell
    return tdElement;
};
