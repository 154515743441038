import * as React from 'react';
import { Link } from 'react-router-dom'
import { useTranslation } from "react-i18next";
import { avaiableLangList } from '../../i18nextConf'
import { Grid, GridToolbar, GridColumn as Column } from '@progress/kendo-react-grid';
import RawMaterialService from "../../services/RawMaterials.service";
import { CustomListCommandCell } from "../../components/GridCustomListCommandCell";
import { GridCustomLoadingCell } from "../../components/GridCustomLoadingCell";
import { PanelCustomLoader } from "../../components/PanelCustomLoader";
import { CustomInput } from "../../components/CustomComponents"
import { Form, Field, FormElement } from "@progress/kendo-react-form";

export default function RawMaterialList(props) {

    const { t } = useTranslation();
    const isAdmin = props.isAdmin;

    const CommandCell = props => (
        <CustomListCommandCell
            {...props}
            editField='inEdit'
            remove={remove}
            linkToDetails={'/RawMaterials/' + props.dataItem.rawMaterialId + '/Edit'}
            isAdmin={isAdmin}
        />
    );

    const [state, setState] = React.useState({ skip: 0, take: 10, reload: false, rawMaterialKeyword: '' });
    const [results, setResults] = React.useState(0);
    const [rawMaterials, setRawMaterials] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const [sorting, setSorting] = React.useState(null);
    const [sortingBy, setSortingBy] = React.useState("rawMaterialGroupId asc, rawMaterialBaseId asc, rawMaterialNumberId asc");


    const remove = (dataItem) => {
        RawMaterialService.deleteRawMaterial(dataItem).then(response => {
            if (response.status === 200)
                setState({ skip: state.skip, take: state.take });
        });
    }

    React.useEffect(() => {

        setLoading(true);

        var selected = localStorage.getItem("i18nextLng") || "en";
        var selectedLangDetails = avaiableLangList.find(lang => lang.short === selected);

        if (state.rawMaterialKeyword)
            RawMaterialService.getrawMaterialsOrderedByAndFiltered(Math.ceil((state.skip + 1) / 10), state.take, sortingBy, selectedLangDetails.id, 'contains(name,\'' + state.rawMaterialKeyword + '\')')
                .then(response => response.data)
                .then(json => {
                    setRawMaterials(json.contract);
                    setResults(json.totalResults);
                    setLoading(false);
                });

        else
            RawMaterialService.getrawMaterialsOrderedBy(Math.ceil((state.skip + 1) / 10), state.take, sortingBy, selectedLangDetails.id)
                .then(response => response.data)
                .then(json => {
                    setRawMaterials(json.contract);
                    setResults(json.totalResults);
                    setLoading(false);
                });


    }, [state, t, sortingBy]);

    const pageChange = (event) => {
        setState({ skip: event.page.skip, take: event.page.take });
    }

    const sortChange = (event) => {

        if (event.sort.length === 0) {
            if (sorting != null) {

                setSorting(null);
                setSortingBy("rawMaterialGroupId asc, rawMaterialBaseId asc, rawMaterialNumberId asc");
            }
        }
        else {

            var sortBy = "";

            if (event.sort[0].field === "rawMaterialId")
                sortBy = "rawMaterialGroupId " + event.sort[0].dir + ", rawMaterialBaseId " + event.sort[0].dir + ", rawMaterialNumberId " + event.sort[0].dir;
            else
                sortBy = event.sort[0].field + " " + event.sort[0].dir;

            setSorting(event.sort);
            setSortingBy(sortBy);
        }

    }

    const handleFilter = (event) => {
        console.log(event.rawMaterialName);
        setState({ skip: state.skip, take: state.take, rawMaterialKeyword: event.rawMaterialName });
    }

    return (
        <div>
            {loading && PanelCustomLoader}

            <div className="card">
                <Form
                    onSubmit={handleFilter}
                    render={(formRenderProps) => (
                        <FormElement>
                            <fieldset>
                                <div className="card-body">
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-sm-6 text-center justify-content-center align-self-center" >
                                                <Field
                                                    name={'rawMaterialName'}
                                                    label={t("RawMaterials_Name_Label")}
                                                    component={CustomInput}
                                                    placeholder={t("RawMaterials_Search_NameContains_Placeholder")}
                                                />
                                            </div>
                                            <div className="col-sm-3 text-center justify-content-center align-self-center">

                                                <br />
                                                <input type="submit" className="k-button k-primary" value={t("RawMaterials_Search_Button_Label")} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </fieldset>
                        </FormElement>)}
                />

            </div>

            <br />

            <Grid
                data={rawMaterials}
                editField='inEdit'
                skip={state.skip}
                take={state.take}
                total={results}
                pageable={{ buttonCount: 4, pageSizes: [10, 20, 50, 100] }}
                scrollable={'none'}
                onPageChange={pageChange}
                cellRender={GridCustomLoadingCell}
                sort={sorting}
                sortable={{
                    allowUnsort: true,
                    mode: 'single'
                }}
                onSortChange={sortChange}
            >
                {isAdmin ? (<GridToolbar>
                    <div>
                        <Link to={'/RawMaterials/Add'}>
                            <button title={t("RawMaterials_Button_Tooltip_New")} className="k-button k-primary">
                                &nbsp;<span className="k-icon k-i-plus-circle" />&nbsp; {t("Global_Button_Text_New")} &nbsp;
                        </button>
                        </Link>
                    </div>
                </GridToolbar>
                ) : (<span />)}

                <Column cell={CommandCell} width={isAdmin ? ("160px") : ("80px")} sortable={false} />
                <Column field="rawMaterialId" title={t("RawMaterials_ID_Label")} />
                <Column field="name" title={t("RawMaterials_Name_Label")} />
                <Column field="description" title={t("RawMaterials_Description_Label")} />

            </Grid>
        </div>
    );
}
