import * as React from 'react';
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { CustomInput, CustomDropDown, CustomCheckbox } from "../../components/CustomComponents"
import AdditiveCategoriesService from "../../services/AdditiveCategories.service";
import LanguagesService from "../../services/Languages.service";
import { useTranslation } from "react-i18next";
import { avaiableLangList } from '../../i18nextConf'
import { DropDownList } from "@progress/kendo-react-dropdowns";
import FunctionalGroupsService from '../../services/FunctionalGroups.service';

export default function Insert(props) {

    const { t } = useTranslation();

    const [languages, setLanguages] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const [selectedLenguage, setSelectedLenguage] = React.useState({ name: 'Loading...', languageId: "es-ES" });
    const [additiveCategories, setAdditiveCategories] = React.useState([]);

    React.useEffect(() => {
        LanguagesService.getLanguages()
            .then(response => response.data.contract)
            .then(json => {
                setLanguages(json);

                var selected = localStorage.getItem("i18nextLng") || "en";
                var selectedLangDetails = avaiableLangList.find(lang => lang.short === selected);
                var defaultLang = json.find(lang => lang.languageId === selectedLangDetails.id);

                setSelectedLenguage(defaultLang);
            });



    }, [t]);

    React.useEffect(() => {

        setLoading(true);
        AdditiveCategoriesService.getAdditiveCategories(selectedLenguage.languageId)
            .then(response => response.data.contract)
            .then(contract => {

                if (contract.length > 0)
                    contract.forEach(x => x.name = x.additiveCategoryId + " - " + x.name);
                setAdditiveCategories(contract);
                setLoading(false);
            });

    }, [selectedLenguage]);

    const [functionalGroup, setFunctionalGroup] = React.useState({
        "functionalGroupId": "",
        "hasAlternativeIdentifier": false,
        "additiveCategory": { "additiveCategoryId": 0, "name": "" },
        "name": ""
    });

    const handleSubmit = (dataItem) => {

        FunctionalGroupsService.create({
            "additiveCategoryId": dataItem.additiveCategory.additiveCategoryId,
            "name": dataItem.name,
            "functionalGroupId": dataItem.functionalGroupId
        }).then(response => response.data.contract).then(end => {
            return window.location.href = "/FunctionalGroups/" + end.functionalGroupId + "/Edit";
        }
        );
    }


    return (
        <div>
            <Form
                onSubmit={handleSubmit}
                initialValues={functionalGroup}
                key={JSON.stringify(functionalGroup)}
                render={(formRenderProps) => (
                    <FormElement>
                        <fieldset>
                            <div className="card">
                                <div className="card-header d-flex justify-content-between align-items-center">

                                    <b>{t("AdditiveCategories_Details_GeneralData_Title")}</b>

                                    <DropDownList
                                        label={t("Global_Label_Text_Language")}
                                        name="language"
                                        textField="name"
                                        value={selectedLenguage}
                                        dataItemKey="languageId"
                                        style={{ width: '200px' }}
                                        onChange={e => setSelectedLenguage(e.value)}
                                        data={languages}
                                    />

                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-sm-3">
                                            <Field
                                                label={t("FunctionalGroups_ID_Label")}
                                                name={'functionalGroupId'}
                                                component={CustomInput}
                                                onChange={e => setFunctionalGroup({ ...functionalGroup, name: e.target.value })}
                                            />
                                        </div>
                                        <div className="col-sm-9">
                                            <Field
                                                label={t("FunctionalGroups_Name_Label")}
                                                name={'name'}
                                                component={CustomInput}
                                                //validator={[requiredValidator]}
                                                onChange={e => setFunctionalGroup({ ...functionalGroup, name: e.target.value })}
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <Field
                                                label={t("FunctionalGroup_AdditiveCategory_Label")}
                                                loading={loading}
                                                name="additiveCategory"
                                                textField="name"
                                                value={functionalGroup.additiveCategoryId}
                                                component={CustomDropDown}
                                                dataItemKey="additiveCategoryId"
                                                style={{ width: "100%" }}
                                                onChange={e => { setFunctionalGroup({ ...functionalGroup, name: e.target.value }) }}
                                                options={additiveCategories}
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-3">
                                            <Field
                                                label={t("FunctionalGroup_HasAlternativeIdentifier_Label")}
                                                name={'hasAlternativeIdentifier'}
                                                onChange={e => setFunctionalGroup({ ...functionalGroup, name: e.target.value })}
                                                component={CustomCheckbox} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </fieldset>
                        <br />
                        <div className="float-sm-right k-form-buttons">
                            <button
                                type={'submit'}
                                disabled={!formRenderProps.allowSubmit}
                                className="k-button"
                            >
                                <span className="k-icon k-i-save" /> &nbsp; {t("Global_Button_Text_Save")}
                            </button>
                        </div>
                    </FormElement>)}
            />
        </div>
    );
}
