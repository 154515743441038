import * as React from 'react';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import RawMaterialService from "../../services/RawMaterials.service";
import { useTranslation } from "react-i18next";
import AnalyticComponentsService from "../../services/AnalyticComponents.service";
import AnalyticComponentsDropDownCell from '../../components/DropDownListCustomAnalyticalComponents';
import checkboxCellGrid from "../../components/GridCustomCheckboxCell";
import { PanelCustomLoader } from "../../components/PanelCustomLoader";

export default function RawMaterialStatementsGroup(props) {

    const { t } = useTranslation();
    const editField = "inEdit";

    const [rawMaterialGroupStatements, setRawMaterialGroupStatements] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [analyticComponents, setAnalyticComponents] = React.useState([]);

    React.useEffect(() => {

        AnalyticComponentsService.getAllAnalyticComponents(1)
            .then(response => response.data)
            .then(json => {
                setAnalyticComponents(json.contract);
            });

    }, []);


    React.useEffect(() => {

        setLoading(true);
        RawMaterialService.getRawMaterialGroupStatements(props.materialData.rawMaterialGroupId)
            .then(response => response.data)
            .then(json => {
                setRawMaterialGroupStatements(json.contract);
                setLoading(false);
            });

    }, [props.materialData]);

    const analyticComponentsDropDownCell = (props) => <AnalyticComponentsDropDownCell {...props} analyticComponents={analyticComponents} />

    return (
        <div>
            {loading && PanelCustomLoader}

            <div className="card">
                <div>

                    <Grid
                        data={rawMaterialGroupStatements}
                        editField={editField}
                        total={rawMaterialGroupStatements.length}
                        pageable={false}
                        scrollable={'none'}
                    >
                        <Column field="analyticComponentId" title={t("RawMaterials_Statement_AnalyticComponentId_Label")} cell={analyticComponentsDropDownCell} />
                        <Column field="maxValue" editor="numeric" title={t("RawMaterials_Statement_MinValue_Label")} />
                        <Column field="minValue" editor="numeric" title={t("RawMaterials_Statement_MaxValue_Label")} />
                        <Column field="isMandatory" cell={checkboxCellGrid} title={t("RawMaterials_Statement_IsMandatory_Label")} />
                    </Grid>
                </div>
            </div>
        </div>
    );

}
