import * as React from 'react';
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import RawMaterialGroupService from "../../services/RawMaterialsGroups.service";
import LanguagesService from "../../services/Languages.service";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { CustomInput } from "../../components/CustomComponents"
import { useTranslation } from "react-i18next";
import { avaiableLangList } from '../../i18nextConf'


export default function RawMaterialGroupEdit(props) {

    const { t } = useTranslation();
    const isAdmin = props.isAdmin;

    const [languages, setLanguages] = React.useState([]);
    const [newResource, setNewResource] = React.useState(false);
    const [selectedLenguage, setSelectedLenguage] = React.useState({ name: 'Loading...', languageId: "es-ES" });

    React.useEffect(() => {
        LanguagesService.getLanguages()
            .then(response => response.data.contract)
            .then(json => {
                setLanguages(json);

                var selected = localStorage.getItem("i18nextLng") || "en";
                var selectedLangDetails = avaiableLangList.find(lang => lang.short === selected);
                var defaultLang = json.find(lang => lang.languageId === selectedLangDetails.id);

                setSelectedLenguage(defaultLang);
            });



    }, []);

    const [editableRawMaterialGroup, setEditableRawMaterialGroup] = React.useState({
        "rawMaterialGroupId": 0,
        "name": ""
    });

    const [loading, setLoading] = React.useState(true);

    React.useEffect(() => {

        if (props.materialData.rawMaterialGroupId)
            RawMaterialGroupService.getLocalization(props.materialData.rawMaterialGroupId, selectedLenguage.languageId)
                .then(json => json.data)
                .then(contract => {
                    setEditableRawMaterialGroup({
                        "rawMaterialGroupId": props.materialData.rawMaterialGroupId,
                        "name": contract.contract.name
                    });
                    setLoading(false);
                })
                .catch(error => {
                    setEditableRawMaterialGroup({
                        "rawMaterialGroupId": props.materialData.rawMaterialGroupId,
                        "name": ""
                    });
                    setNewResource(true);
                    setLoading(false);
                });;



    }, [selectedLenguage, props.materialData]);


    const requiredValidator = (value) => {
        return value ? "" : "This field is required";
    }

    const handleSubmit = (dataItem) => {

        if (newResource) {
            RawMaterialGroupService.saveLocalization({
                "rawMaterialGroupId": parseInt(props.materialData.rawMaterialGroupId),
                "languageId": selectedLenguage.languageId,
                "name": dataItem.name
            });
        }
        else {
            RawMaterialGroupService.updateLocalization({
                "rawMaterialGroupId": parseInt(props.materialData.rawMaterialGroupId),
                "name": dataItem.name,
                "languageId": selectedLenguage.languageId
            }, selectedLenguage.languageId);
        }

        setEditableRawMaterialGroup(dataItem);

    }

    return (
        <div>
            <Form
                onSubmit={handleSubmit}
                initialValues={editableRawMaterialGroup}
                key={JSON.stringify(editableRawMaterialGroup)}
                render={(formRenderProps) => (
                    <FormElement>
                        <fieldset>
                            <div className="card">
                                <div className="card-header d-flex justify-content-between align-items-center">

                                    <b>{t("RawMaterials_Details_GeneralData_Title")}</b>

                                    <DropDownList
                                        label={t("Global_Label_Text_Language")}
                                        name="language"
                                        loading={loading}
                                        textField="name"
                                        value={selectedLenguage}
                                        dataItemKey="languageId"
                                        style={{ width: '200px' }}
                                        onChange={e => setSelectedLenguage(e.value)}
                                        data={languages}
                                    />

                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-sm-3">
                                            <Field
                                                label={t("RawMaterials_ID_Label")}
                                                loading={loading}
                                                disabled={true}
                                                name={'rawMaterialGroupId'}
                                                component={CustomInput}
                                                onChange={e => setEditableRawMaterialGroup({ ...editableRawMaterialGroup, name: e.target.value })}
                                            />
                                        </div>
                                        <div className="col-sm-6">
                                            <Field
                                                label={t("RawMaterials_Name_Label")}
                                                name={'name'}
                                                loading={loading}
                                                disabled={!isAdmin}
                                                component={CustomInput}
                                                validator={[requiredValidator]}
                                                onChange={e => setEditableRawMaterialGroup({ ...editableRawMaterialGroup, name: e.target.value })}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </fieldset>
                        {isAdmin ?
                            (<div className="float-sm-right k-form-buttons">
                                <button
                                    type={'submit'}
                                    disabled={!formRenderProps.allowSubmit}
                                    className="k-button"
                                >
                                    <span className="k-icon k-i-save" /> &nbsp; {t("Global_Button_Text_Save")}
                                </button>
                            </div>) : (<span />)}
                    </FormElement>)}
            />

            <br />
            <br />
        </div>
    );
}
