import axios from 'axios';
import authHeader from './auth-header';
import AuthService from './auth.service'
const API_URL = 'https://flbasisapi.agrifoodatsolutions.com';
const axiosApiInstanceForFunctionalGroups = axios.create();

axiosApiInstanceForFunctionalGroups.interceptors.response.use(response => {
    return response;
}, async error => {
    const originalRequest = error.config;
    if (error.response.status === 401) {

        originalRequest._retry = true;
        await AuthService.relogin();
        originalRequest.headers = authHeader();
        return axiosApiInstanceForFunctionalGroups(originalRequest);
    }

    if (error.response.status === 404) {
        console.log(error);
    }

    return Promise.reject(error);
});

class FunctionalGroupsService {


    getFunctionalGroups(languageId) {
        return axiosApiInstanceForFunctionalGroups.get(API_URL + '/v1/functionalGroups?languageId=' + languageId, { headers: authHeader() });
    }

    getGroup(id) {
        return axiosApiInstanceForFunctionalGroups.get(API_URL + '/v1/functionalGroups/' + id, { headers: authHeader() });
    }

    getGroupLocalization(id, langId) {
        return axiosApiInstanceForFunctionalGroups.get(API_URL + '/v1/functionalGroups/' + id + '/localization/' + langId, { headers: authHeader() });
    }

    create(FuncionalGroup) {
        return axiosApiInstanceForFunctionalGroups.post(API_URL + '/v1/functionalGroups', FuncionalGroup, { headers: authHeader() });
    }

    update(FuncionalGroup) {
        return axiosApiInstanceForFunctionalGroups.put(API_URL + '/v1/functionalGroups/' + FuncionalGroup.functionalGroupId, FuncionalGroup, { headers: authHeader() });
    }

    updateLocalization(FuncionalGroupLocalization) {
        return axiosApiInstanceForFunctionalGroups.put(API_URL + '/v1/functionalGroups/' + FuncionalGroupLocalization.functionalGroupId + '/localization/' + FuncionalGroupLocalization.languageId, FuncionalGroupLocalization, { headers: authHeader() });
    }

    insertLocalization(FuncionalGroupLocalization) {
        return axiosApiInstanceForFunctionalGroups.post(API_URL + '/v1/functionalGroups/' + FuncionalGroupLocalization.functionalGroupId + '/localization', FuncionalGroupLocalization, { headers: authHeader() });
    }

    delete(FuncionalGroup) {
        return axiosApiInstanceForFunctionalGroups.delete(API_URL + '/v1/functionalGroups/' + FuncionalGroup.functionalGroupId, { headers: authHeader() });
    }

    getFunctionalGroupAdditives(functionalGroupId) {
        return axiosApiInstanceForFunctionalGroups.get(API_URL + '/v1/functionalGroups/' + functionalGroupId + '/additives', { headers: authHeader() });
    }

    deleteFunctionalGroupAdditive(functionalGroupId, additiveId) {
        return axiosApiInstanceForFunctionalGroups.delete(API_URL + '/v1/functionalGroups/' + functionalGroupId + '/additives/' + additiveId, { headers: authHeader() });
    }

    saveFunctionalGroupAdditive(functionalGroupId, functionalGroupAdditive) {
        return axiosApiInstanceForFunctionalGroups.post(API_URL + '/v1/functionalGroups/' + functionalGroupId + '/additives', functionalGroupAdditive, { headers: authHeader() });
    }

}

export default new FunctionalGroupsService();
