import React from 'react';
import AdditiveComponentlInsert from '../../Models/AdditiveCategory/Insert';
import AuthService from "../../services/auth.service";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export default function AddAdditiveCategory(props) {

    const { t } = useTranslation();

    return (
        <div>
            <br />
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to={'/home/'}>{t("Home_Breadcrumb_Title")}</Link></li>
                    <li className="breadcrumb-item"><Link to={'/AdditiveCategories/'}>{t("AdditiveCategories_Breadcrumb_Title")}</Link></li>
                    <li className="breadcrumb-item active">{t("Global_Button_Text_New")}</li>
                </ol>
            </nav>
            <br />
            {AuthService.isUSerAdmin() ? (<AdditiveComponentlInsert />) : (
                <div className="jumbotron">
                    <h1 className="display-4">{t("Global_Message_Title_UserNotAllowed")}</h1>
                    <p className="lead">{t("Global_Message_Text_UserNotAllowed")}</p>
                </div>
            )}
            <br />
            <br />
        </div >
    );

}
