import React from 'react';
import FunctionalGroup from '../../Models/Additive/FunctionalGroup';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import AuthService from "../../services/auth.service";

export default function FunctionalGroups(props) {

    const { params } = props.match;
    const { t } = useTranslation();

    return (
        <div>
            <br />
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to={'/home/'}>{t("Home_Breadcrumb_Title")}</Link></li>
                    <li className="breadcrumb-item"><Link to={'/Additives'}>{t("Additives_Breadcrumb_Title")}</Link></li>
                    <li className="breadcrumb-item active">{params.additiveId}</li>
                </ol>
            </nav>
            <div className="card text-center">
                <div className="card-header">
                    <ul className="nav nav-tabs card-header-tabs">
                        <li className="nav-item">
                            <Link to={'/Additives/' + params.additiveId + '/Edit/'} className="nav-link">{t("Additive_Details_Tab")}</Link>
                        </li>
                        <li className="nav-item">
                            <Link to={'/Additives/' + params.additiveId + '/Edit/AlternativeIdentifiers'} className="nav-link">{t("Additive_AlternativeIdentifiers_Tab")}</Link>
                        </li>
                        <li className="nav-item">
                            <Link to={'/Additives/' + params.additiveId + '/Edit/FunctionalGroups'} className="nav-link active">{t("Additive_FunctionalGroups_Tab")}</Link>
                        </li>
                    </ul>
                </div>
                <div className="card-body">
                    <FunctionalGroup
                        additiveId={params.additiveId}
                        isAdmin={AuthService.isUSerAdmin()}
                    />
                </div>
            </div>
            <br />
        </div >
    );


}
